<template>
    <a-select
        :value="multiple? personList : person"
        :mode="multiple?'multiple':'single'"
        show-search
        :show-arrow="false"
        placeholder="Type to search..."
        size="large"
        :filter-option="false"
        @search="suggestContact"
        :not-found-content="null"
        :disabled="disabled"
        :options="queriedContacts"
        @change="handleChange"
		:allowClear="allowClear"
        >
    </a-select>
</template>

<script>
export default {
    props: {
        multiple: {
            default: false,
            type: Boolean,
        },
        disabled: {
            default: false,
            type: Boolean,
        },
		allowClear: {
			default: false,
			type: Boolean,
		},
		id: {
			default: '',
			type: String,
		}
    },
    data() {
        return {
            timeout:null,
            queriedContacts:[],
            person:'',
            personList:[],
        }
    },
    methods: {
        async updateList(input) {
			try {
				let { data } = await this.$api.get(`/contacts/:instance/autocomplete?q=${input}`)
				this.queriedContacts = data.map(({ name, id, email }) => {
					let obj = {}
					if (!name.trim() || !name) obj.label = email
					else obj.label = name + ' (' + email + ')'
					obj.value = id
					return obj
				})
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			}
		},
		suggestContact(input) {
			if (input.length < 3) {
				return this.timerId = undefined
			}
			const makeAPICall = () => {
				this.updateList(input)
			}

			if (this.timerId) clearTimeout(this.timerId)

            this.timerId  =  setTimeout(function () {
                makeAPICall()
                this.timerId  =  undefined;
            }, 400)
        },
		handleChange(value) {
			if (this.multiple) {
				this.personList = value;
			} else {
				this.person = value;
			}
			this.$emit('updatecontact', value)
		}
    },

	async mounted() {
		if (this.id) {
			await this.updateList('@')
			this.handleChange(this.id)
		}
	},
}
</script>

<style>

</style>
