<template>
    <a-modal :visible="visible" @cancel="onClose" width="700px" centered :footer="null" :zIndex="1001">
        <h5>Add New Plain Text Template</h5>
        <a-form-model class="mt-4" ref="newTemplate" :model="newTemplate">
            <a-form-model-item label="Template Name" required prop="name" :rules="req('Please enter the template name')">
                <a-input size="large" v-model="newTemplate.name" placeholder="Add template name" />
            </a-form-model-item>
        </a-form-model>
        <div class="dF" style="justify-content:flex-end">
            <a-button style="width:116px" @click="onClose" class="mr-3 cancel-button" size="large">CANCEL</a-button>
            <a-button style="width:116px" @click="saveTemplate" size="large" type="primary">SAVE</a-button>
        </div>
    </a-modal>
</template>

<script>
export default {
    computed:{
        visible(){
            return this.$store.state.contacts.emailTemplate.visible
        },
        emailTemplate(){
            return this.$store.state.contacts.emailTemplate.data
        },
        settings(){
            return this.$store.state.contacts.allSettings.app
        },
        user(){
            return this.$store.state.user.user
        }
    },
    data(){
        return{
            newTemplate:{
                name:''
            }
        }
    },
    methods:{
        req:msg=>({required:true,message:msg}),
        onClose(){
			if(this.$refs && this.$refs.newTemplate) {
				this.$refs.newTemplate.resetFields()
			}
            this.$store.commit('CLOSE_EMAIL_TEMPLATE')
        },
        saveTemplate(){
            this.$refs.newTemplate.validate(valid => {
                if (valid) {
                    console.log('template', this.emailTemplate, this.settings)
                    console.log('settings', this.settings)
                    let settings = JSON.parse(JSON.stringify(this.settings))

                    let template = {
						attachments: this.emailTemplate.attachments,
                        name:this.newTemplate.name,
                        subject:this.emailTemplate.subject,
                        message:this.emailTemplate.message,
                        createdBy:this.user,
                        createdAt:Date.now()
                    }
                    console.log('userr',this.user)
                    console.log('template', template)
                    if (settings.options.templateList) {
                        settings.options.templateList.push(template)
                    } else {
                        settings.options.templateList = [template]

                    }
                    console.log('settings',settings)
                    this.$api.post(`/settings/:instance/contacts`, settings).then(({data}) => {
                        this.$store.commit('UPDATE_ALLSETTINGS', data)
                        this.$store.commit('CLOSE_EMAIL_TEMPLATE')
						this.$store.commit('CLOSE_ADD_NEW')
            			this.$store.commit('CLEAR_MESSAGE')
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
}
</script>

<style>

</style>
