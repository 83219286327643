import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import contacts from './appData'
import settings from './settings'
import library from './library'
import { $api } from 'bh-mod'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		user,
		settings,
		contacts,
		library,
	},
	state: {
		hasDashboard: true,
		instances: [],
		instance: null,
		hives: [],
		cats: [],
		uploadDialog: false,
		loading: false,
		folders: [],
		files: {},
		currentFolder: '/',
		theApp: {},
		addNew: {
			type: '',
			visible: false,
			cb: () => { }
		},

		sidebarSelection: 'overview',
		duplicate: {
			data: {},
			time: ''
		},
		products: [],
		elevations: [],
		loadedProducts: false,
		timeLoaded: 0,
		fileStructure: []
	},
	mutations: {
		SET_FILE_STRUCTURE(state, data) {
			state.fileStructure = data;
		},
		LOAD: (state, bool = !state.loading) => state.loading = bool,
		DUPLICATE(state, data) {
			state.duplicate = {
				data: data,
				time: Date.now()
			}
		},
		ADD_NEW(state, data, cb = () => { }) {
			state.addNew = {
				type: data,
				visible: true,
				cb
			}
		},
		CLOSE_ADD_NEW(state) {
			state.addNew = {
				type: '',
				visible: false,
				cb: () => { }
			}
		},
		SIDEBAR_SELECTION(state, data) {
			state.sidebarSelection = data.value
			if (!state.loadedProducts || Date.now() > state.timeLoaded + 120000) {
				if (state.instance.productType == 'lowrise') {
					$api.get(`/architectural-controls/:instance/availablelots`).then(({ data }) => {
						state.products = data
						state.loadedProducts = true
						state.timeLoaded = Date.now()
					}).catch((error) => {
						console.log('Error occurred while fetching available lots.', error)
					})
					$api.get(`/units/:instance`).then(({ data }) => {
						state.elevations = data
						state.timeLoaded = Date.now()
					}).catch((error) => {
						console.log('Error occurred while fetching units.', error)
					})
				} else if (state.instance.productType == 'highrise') {
					$api.get(`/unit-groups/:instance`).then(({ data }) => {
						state.products = data
						state.loadedProducts = true
						state.timeLoaded = Date.now()
					}).catch((error) => {
						console.log('Error occurred while fetching unit-groups.', error)
					})
				}
			}
		},
		SET_APP(state, data) {
			state.theApp = data
		},
		cancelUpload: (state) => {
			state.uploadDialog = false
		},
		uploadDialog: (state, folders = []) => {
			state.uploadDialog = true
			state.folders = folders
		},
		SET_INSTANCE: (state, data) => {
			state.instance = data
			state.cats = data.activeApps
		},
		UPDATE_VERIFICATION_FLAG: (state, data) => {
			Vue.set(state.instance, 'isVerificationsOn', !!data)
		},
		SET_INSTANCES: (state, data) => {
			state.instances = data
		}
	},
	getters: {},
	actions: {
		FETCH_INSTANCE_DETAILS: async ({ commit }) => {
			try {
				const { data } = await $api.get(`/instances/:instance`)
				commit('UPDATE_VERIFICATION_FLAG', data && data.isVerificationsOn)
			} catch (error) {
				console.error(error)
			}
		},
		FETCH_INSTANCES: async ({ commit }) => {
			try {
				let { data } = await $api.get(`/instances`)
				data = data.filter(x => x.role && x.role.name === 'bh_admin').map(x => {
					return {
						id: x.instance.id,
						name: x.instance.name
					}
				})
				commit('SET_INSTANCES', data)
			} catch (error) {
				console.error(error)
			}
		}
	},
})
