<template>
    <a-modal v-if="previewEmail.data.subject" :title="`Subject: ${previewEmail.data.subject}`" :visible="visible && loaded" @cancel="onClose" width="700px" centered :footer="null" :zIndex="1002">
        <!-- <h5>Subject: {{previewEmail.data.subject}}</h5> -->

        <p>from: {{ nylasAccount.email_address }}</p>

        <div class="my-5 email-message" v-html="message" :style="`width: 500px; font-family: ${account.fontFamily};`"></div>

        <div id="template_1" v-if="previewEmail.data.showSignature && account.template == '1'" :style="`font-family: ${account.fontFamily}; width: 500px;`">
            <div class="dF aS jS" style="gap: 2em;">
				<div style="width: 40%;">
					<div v-if="account.logo && account.logo != ''" class="w-full dF jC">
						<img :src="account.logo" style="width: 80%; object-fit:contain" />
					</div>
					<div class="dF jC w-full mt-4">
						<div class="aC" style="display: grid; grid-template-columns: repeat(5, minmax(0px, 1fr)); gap: 7px;">
							<div v-for="(social, socialI) in getSocials(account.socials)" :key="social.id">
								<div style="width: 20px; text-align: center;">
									<img style="object-fit:cover; height:18px; width:18px"
										src="https://bildhive.nyc3.digitaloceanspaces.com/v3/facebook_icon_1f824839f3.png"
										v-if="social.type == 'Facebook'" />
									<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/linkedin_icon_6fd5b99eca.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'LinkedIn'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/twitter_logo_email_2343518957.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Twitter'" />
									<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/instagram_icon_8eb7fba8c2.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Instagram'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/youtube_c77a285d7a.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'YouTube'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/google_plus_2082ce542c.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Google+'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/pinterest_30db360395.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Pinterest'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/wechat_c566d59358.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'WeChat'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/quora_2fb387fc08.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Quora'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/foursquare_54e2bbc3e4.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Foursquare'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/klout_d89258e142.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Klout'" />
								</div>
							</div>
						</div>
					</div>
					<div v-if="account.appointment" class="mt-4">
						<a-button style="pointer-events: none;"
							:style="`color:${account.appointmentColor}; background-color: ${account.appointmentBgColor}`">{{
								account.appointmentText }}</a-button>
					</div>
				</div>
				<div style="width: 60%;">
					<div style="font-weight: bold;">{{ account.name.toUpperCase() }}</div>
					<div><small>{{ account.jobTitle }}</small></div>
					<div class="mb-3"><small>{{ account.company }}</small></div>
					<div v-if="account.email && account.email != ''" class="dF aS">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Email:</small></span>
						<div class="f1"><small>{{ account.email }}</small></div>
					</div>
					<div v-if="account.address && account.address != ''" class="dF aS">
						<span style="width: 65px;"><small
								:style="`color:${account.fieldColor};`">Address:</small></span>
						<div class="f1"><small>{{ account.address }}</small></div>
					</div>
					<div class="dF aS mb-3" v-if="account.website && account.website != ''">
						<span style="width: 65px;"><small
								:style="`color:${account.fieldColor};`">Website:</small></span>
						<div class="f1"><small>{{ account.website }}</small></div>
					</div>
					<div class="dF aS" v-if="account.phone.value">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">{{ account.phone.type }}:</small></span>
						<div class="f1"><small>{{ account.phone.value }}</small></div>
					</div>
					<div v-if="account.officePhone" class="dF aS">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Office:</small></span>
						<div class="f1"><small>{{ account.officePhone }}</small></div>
					</div>
					<div v-if="account.fax" class="dF aS">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Fax:</small></span>
						<div class="f1"><small>{{ account.fax }}</small></div>
					</div>
				</div>
			</div>
        </div>
        <div id="template_2" v-else-if="previewEmail.data.showSignature && account.template == '2'" :style="`font-family: ${account.fontFamily}; width: 500px;`">
            <div class="w-full dF jC">
				<img v-if="account.logo && account.logo != ''" :src="account.logo"
					style="width: 40%; object-fit:contain;" />
			</div>
			<div class="mt-3">
				<div class="text-center" style="font-weight: bold;">{{ account.name.toUpperCase() }}</div>
				<div class="text-center"><small>{{ account.jobTitle }}</small></div>
				<div class="text-center"><small>{{ account.company }}</small></div>
				<div v-if="account.appointment" class="mt-3 text-center">
					<a-button style="pointer-events: none;"
						:style="`color:${account.appointmentColor}; background-color: ${account.appointmentBgColor}`">{{
							account.appointmentText }}</a-button>
				</div>
			</div>
			<div class="mt-3" style="display:flex; gap:20px">
				<div style="width: 65%;">
					<div v-if="account.email && account.email != ''" class="dF aS">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Email:</small></span>
						<div class="f1"><small>{{ account.email }}</small></div>
					</div>
					<div v-if="account.address && account.address != ''" class="dF aS">
						<span style="width: 65px;"><small
								:style="`color:${account.fieldColor};`">Address:</small></span>
						<div class="f1"><small>{{ account.address }}</small></div>
					</div>
					<div class="dF aS mb-3" v-if="account.website && account.website != ''">
						<span style="width: 65px;"><small
								:style="`color:${account.fieldColor};`">Website:</small></span>
						<div class="f1"><small>{{ account.website }}</small></div>
					</div>
					<div class="dF aS" v-if="account.phone.value">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">{{ account.phone.type
								}}:</small></span>
						<div class="f1"><small>{{ account.phone.value }}</small></div>
					</div>
					<div v-if="account.officePhone" class="dF aS">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Office:</small></span>
						<div class="f1"><small>{{ account.officePhone }}</small></div>
					</div>
					<div v-if="account.fax" class="dF aS">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Fax:</small></span>
						<div class="f1"><small>{{ account.fax }}</small></div>
					</div>
				</div>
				<div class="text-right" style="width: 35%;">
					<div class="dF jE">
						<div class="grid w-full"
							style="grid-template-columns: repeat(4, minmax(0, 1fr)); direction: rtl; gap: 7px;">
							<div v-for="(social, socialI) in getSocials(account.socials)" :key="social.id">
								<div style="width: 20px; text-align: center;">
									<img style="object-fit:cover; height:18px; width:18px"
										src="https://bildhive.nyc3.digitaloceanspaces.com/v3/facebook_icon_1f824839f3.png"
										v-if="social.type == 'Facebook'" />
									<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/linkedin_icon_6fd5b99eca.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'LinkedIn'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/twitter_logo_email_2343518957.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Twitter'" />
									<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/instagram_icon_8eb7fba8c2.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Instagram'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/youtube_c77a285d7a.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'YouTube'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/google_plus_2082ce542c.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Google+'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/pinterest_30db360395.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Pinterest'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/wechat_c566d59358.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'WeChat'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/quora_2fb387fc08.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Quora'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/foursquare_54e2bbc3e4.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Foursquare'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/klout_d89258e142.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Klout'" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        </div>
        <div id="template_3" v-if="previewEmail.data.showSignature && account.template == '3'" :style="`font-family: ${account.fontFamily}; width: 500px;`">
            <div class="dF">
				<div class="relative" style="width: 40%">
					<img style="object-fit:contain; width: 100%;" v-if="account.logo && account.logo != ''"
						:src="account.logo" />
					<div class="mt-3 ">
						<div style="text-align: center; font-weight: bold;">{{ account.name.toUpperCase() }}</div>
						<div style="text-align: center;"><small>{{ account.jobTitle }}</small></div>
						<div style="text-align: center;"><small>{{ account.company }}</small></div>
						<div v-if="account.appointment" class="mt-3">
							<a-button style="pointer-events: none;"
								:style="`color:${account.appointmentColor}; background-color: ${account.appointmentBgColor}`">{{
									account.appointmentText }}</a-button>
						</div>
					</div>
				</div>
				<div class="ml-5 relative" style="width: 60%;">
					<div v-if="account.email && account.email != ''" class="dF aS">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Email:</small></span>
						<div class="f1"><small>{{ account.email }}</small></div>
					</div>
					<div v-if="account.address && account.address != ''" class="dF aS">
						<span style="width: 65px;"><small
								:style="`color:${account.fieldColor};`">Address:</small></span>
						<div class="f1"><small>{{ account.address }}</small></div>
					</div>
					<div class="dF aS mb-3" v-if="account.website && account.website != ''">
						<span style="width: 65px;"><small
								:style="`color:${account.fieldColor};`">Website:</small></span>
						<div class="f1"><small>{{ account.website }}</small></div>
					</div>
					<div class="dF aS" v-if="account.phone.value">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">{{ account.phone.type
								}}:</small></span>
						<div class="f1"><small>{{ account.phone.value }}</small></div>
					</div>
					<div v-if="account.officePhone" class="dF aS">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Office:</small></span>
						<div class="f1"><small>{{ account.officePhone }}</small></div>
					</div>
					<div v-if="account.fax" class="dF aS">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Fax:</small></span>
						<div class="f1"><small>{{ account.fax }}</small></div>
					</div>

					<div class="dF jE mt-3">
						<div class="dF aC" style="flex-wrap: wrap; gap: 7px;">
							<div v-for="(social, socialI) in getSocials(account.socials)" :key="social.id">
								<div style="width: 20px; text-align: center;">
									<img style="object-fit:cover; height:18px; width:18px"
										src="https://bildhive.nyc3.digitaloceanspaces.com/v3/facebook_icon_1f824839f3.png"
										v-if="social.type == 'Facebook'" />
									<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/linkedin_icon_6fd5b99eca.png"
										style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'LinkedIn'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/twitter_logo_email_2343518957.png"
										style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'Twitter'" />
									<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/instagram_icon_8eb7fba8c2.png"
										style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'Instagram'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/youtube_c77a285d7a.png"
										style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'YouTube'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/google_plus_2082ce542c.png"
										style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'Google+'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/pinterest_30db360395.png"
										style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'Pinterest'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/wechat_c566d59358.png"
										style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'WeChat'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/quora_2fb387fc08.png"
										style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'Quora'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/foursquare_54e2bbc3e4.png"
										style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'Foursquare'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/klout_d89258e142.png"
										style="object-fit:cover; height:18px; width:18px" v-if="social.type == 'Klout'" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        </div>
        <div id="template_4" v-if="previewEmail.data.showSignature && account.template == '4'" :style="`font-family: ${account.fontFamily}; width: 500px;`">
            <div class="dF">
				<div class="mr-4" style="width: 65%;">
					<div class="mb-3">
						<div style="font-weight: bold;">{{ account.name.toUpperCase() }}</div>
						<div><small>{{ account.jobTitle }}</small></div>
						<div><small>{{ account.company }}</small></div>
					</div>
					<div v-if="account.email && account.email != ''" class="dF aS">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Email:</small></span>
						<div class="f1"><small>{{ account.email }}</small></div>
					</div>
					<div v-if="account.address && account.address != ''" class="dF aS">
						<span style="width: 65px;"><small
								:style="`color:${account.fieldColor};`">Address:</small></span>
						<div class="f1"><small>{{ account.address }}</small></div>
					</div>
					<div class="dF aS mb-3" v-if="account.website && account.website != ''">
						<span style="width: 65px;"><small
								:style="`color:${account.fieldColor};`">Website:</small></span>
						<div class="f1"><small>{{ account.website }}</small></div>
					</div>
					<div class="dF aS" v-if="account.phone.value">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">{{ account.phone.type
								}}:</small></span>
						<div class="f1"><small>{{ account.phone.value }}</small></div>
					</div>
					<div v-if="account.officePhone" class="dF aS">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Office:</small></span>
						<div class="f1"><small>{{ account.officePhone }}</small></div>
					</div>
					<div v-if="account.fax" class="dF aS">
						<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Fax:</small></span>
						<div class="f1"><small>{{ account.fax }}</small></div>
					</div>
					<div v-if="account.appointment" class="mt-4">
						<a-button style="pointer-events: none;"
							:style="`color:${account.appointmentColor}; background-color: ${account.appointmentBgColor}`">{{
								account.appointmentText }}</a-button>
					</div>
				</div>
				<div class="dF jE relative" style="width: 35%;">
					<div class="relative w-full">
						<div class="dF jC">
							<img style="object-fit:contain; width: 75%;"
								v-if="account.logo && account.logo != ''" :src="account.logo" />
						</div>
						<div class="mt-4 w-full dF jC">
							<div class="grid" style="grid-template-columns: repeat(4, minmax(0, 1fr)); gap: 0.5em;">
								<div v-for="(social, socialI) in getSocials(account.socials)" :key="social.id">
									<div style="width: 20px; text-align: center;">
										<img style="object-fit:cover; height:18px; width:18px"
											src="https://bildhive.nyc3.digitaloceanspaces.com/v3/facebook_icon_1f824839f3.png"
											v-if="social.type == 'Facebook'" />
										<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/linkedin_icon_6fd5b99eca.png"
											style="object-fit:cover; height:18px; width:18px"
											v-if="social.type == 'LinkedIn'" />
										<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/twitter_logo_email_2343518957.png"
											style="object-fit:cover; height:18px; width:18px"
											v-if="social.type == 'Twitter'" />
										<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/instagram_icon_8eb7fba8c2.png"
											style="object-fit:cover; height:18px; width:18px"
											v-if="social.type == 'Instagram'" />
										<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/youtube_c77a285d7a.png"
											style="object-fit:cover; height:18px; width:18px"
											v-if="social.type == 'YouTube'" />
										<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/google_plus_2082ce542c.png"
											style="object-fit:cover; height:18px; width:18px"
											v-if="social.type == 'Google+'" />
										<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/pinterest_30db360395.png"
											style="object-fit:cover; height:18px; width:18px"
											v-if="social.type == 'Pinterest'" />
										<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/wechat_c566d59358.png"
											style="object-fit:cover; height:18px; width:18px"
											v-if="social.type == 'WeChat'" />
										<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/quora_2fb387fc08.png"
											style="object-fit:cover; height:18px; width:18px"
											v-if="social.type == 'Quora'" />
										<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/foursquare_54e2bbc3e4.png"
											style="object-fit:cover; height:18px; width:18px"
											v-if="social.type == 'Foursquare'" />
										<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/klout_d89258e142.png"
											style="object-fit:cover; height:18px; width:18px"
											v-if="social.type == 'Klout'" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        </div>
		<div id="template_5" v-if="previewEmail.data.showSignature && account.template == '5'" :style="`font-family: ${account.fontFamily}; width: 500px;`">
            <div class="dF jE relative" style="width: 35%;">
				<div class="relative w-full">
					<div class="dF jS">
						<img style="object-fit:contain; width: 75%;"
							v-if="account.logo && account.logo != ''" :src="account.logo" />
					</div>
					<div class="mt-4 w-full dF jS">
						<div class="dF aC" style="gap: 0.5em;">
							<div v-for="(social, socialI) in getSocials(account.socials)" :key="social.id">
								<div style="width: 20px; text-align: center;">
									<img style="object-fit:cover; height:18px; width:18px"
										src="https://bildhive.nyc3.digitaloceanspaces.com/v3/facebook_icon_1f824839f3.png"
										v-if="social.type == 'Facebook'" />
									<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/linkedin_icon_6fd5b99eca.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'LinkedIn'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/twitter_logo_email_2343518957.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Twitter'" />
									<img src="https://bildhive.nyc3.digitaloceanspaces.com/v3/instagram_icon_8eb7fba8c2.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Instagram'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/youtube_c77a285d7a.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'YouTube'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/google_plus_2082ce542c.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Google+'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/pinterest_30db360395.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Pinterest'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/wechat_c566d59358.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'WeChat'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/quora_2fb387fc08.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Quora'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/foursquare_54e2bbc3e4.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Foursquare'" />
									<img src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/klout_d89258e142.png"
										style="object-fit:cover; height:18px; width:18px"
										v-if="social.type == 'Klout'" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="mt-3 mr-4" style="width: 65%;">
				<div class="mb-3">
					<div style="font-weight: bold;">{{ account.name.toUpperCase() }}</div>
					<div><small>{{ account.jobTitle }}</small></div>
					<div><small>{{ account.company }}</small></div>
				</div>
				<div v-if="account.email && account.email != ''" class="dF aS">
					<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Email:</small></span>
					<div class="f1"><small>{{ account.email }}</small></div>
				</div>
				<div v-if="account.address && account.address != ''" class="dF aS">
					<span style="width: 65px;"><small
							:style="`color:${account.fieldColor};`">Address:</small></span>
					<div class="f1"><small>{{ account.address }}</small></div>
				</div>
				<div class="dF aS mb-3" v-if="account.website && account.website != ''">
					<span style="width: 65px;"><small
							:style="`color:${account.fieldColor};`">Website:</small></span>
					<div class="f1"><small>{{ account.website }}</small></div>
				</div>
				<div class="dF aS" v-if="account.phone.value">
					<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">{{ account.phone.type
							}}:</small></span>
					<div class="f1"><small>{{ account.phone.value }}</small></div>
				</div>
				<div v-if="account.officePhone" class="dF aS">
					<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Office:</small></span>
					<div class="f1"><small>{{ account.officePhone }}</small></div>
				</div>
				<div v-if="account.fax" class="dF aS">
					<span style="width: 65px;"><small :style="`color:${account.fieldColor};`">Fax:</small></span>
					<div class="f1"><small>{{ account.fax }}</small></div>
				</div>
				<div v-if="account.appointment" class="mt-4">
					<a-button style="pointer-events: none;"
						:style="`color:${account.appointmentColor}; background-color: ${account.appointmentBgColor}`">{{
							account.appointmentText }}</a-button>
				</div>
			</div>
        </div>
		<template v-if="previewEmail && previewEmail.data && previewEmail.data.attachments && previewEmail.data.attachments.length">
            <div class="mt-5 attachments">
                <a-icon class="mr-2" type="paper-clip" />{{previewEmail.data.attachments.length}} {{previewEmail.data.attachments.length > 1 ? 'attachments' : 'attachment'}}
            </div>
            <div class="mt-3" style="display: grid; grid-template-columns: repeat(3, minmax(0, 1fr)); gap:2em;">
                <div v-for="(file,fileI) in previewEmail.data.attachments" :key="file+fileI" class="dF aC jSB px-2 py-1" style="background-color: #F7F5F9; border: 1px solid #E6EAF0; border-radius: 5px;">
                    <div style="overflow: hidden; white-space: nowrap;">
                        <div>{{file.name}}</div>
                        <div style="color: #A3A7AC;">{{fileSize(file.size)}}</div>
                    </div>
                </div>
            </div>
        </template>
		<a-alert message="Emails you send will be stored in Bildhive's database and can be viewed by users with the appropriate permissions." type="info" show-icon  class="mt-5"/>
        <div class="dF aC mt-5" style="justify-content:space-between;">
			<a-button @click="sendEmail(true)" size="large" class="mr-3 cancel-button">SEND TEST</a-button>
			<div class="dF aC">
				<a-button @click="onClose" size="large" class="mr-3 cancel-button">CANCEL</a-button>
				<a-button @click="sendEmail(false)" size="large" type="primary">SEND</a-button>
			</div>
        </div>
    </a-modal>
</template>

<script>
export default {
    computed:{
        user(){
            return this.$store.state.user.user
        },
        visible(){
            return this.$store.state.contacts.previewEmail.visible
        },
        previewEmail(){
            return this.$store.state.contacts.previewEmail
        },
		searchContacts() {
			let refresh = this.refresh;
			let contacts = []
			Object.values(this.allContacts).forEach(x => {
				contacts.push(x)
			})
			Object.values(this.externalContacts).forEach(x => {
				contacts.push(x)
			})
			return contacts
		},
		allContacts(){
			return this.$store.state.contacts.allContacts
		},
		externalContacts(){
			return this.$store.state.contacts.externalContacts
		},
        account() {
            return this.$store.state.contacts.allSettings.userApp.options.account
        },
		settings(){
			return this.$store.state.contacts.allSettings.app
		},
        vendor(){
            let vendors = Object.values(this.$store.state.contacts.vendors)
			return vendors.find(v => v.active)
        },
		nylasAccount() {
            return this.$store.state.contacts.nylasAccount || {}
        }
    },
    data(){
        return{
            contact:{},
            message:'',
            contacts:[],
            template1: '',
            template2:'',
            template3:'',
			loaded:false,
			refresh:0
        }
    },
    watch:{
        previewEmail:{
            handler(val) {
                if(val.visible) {
                    console.log('Preview Email...', val)
					console.log('SETTINGS', this.vendor)
                    if (typeof val.data.to == 'string') {
                        let index = this.searchContacts.findIndex(x => x.id == val.data.to)
                        if (index != -1) {
                            console.log('foundd', this.searchContacts[index])
                            this.contact = this.searchContacts[index]
							if(val.data.message) {
								this.message = val.data.message
								this.replaceVariables();
							} else {
								this.message = val.data.message
							}
							this.loaded = true;
                        } else if (index == -1 && val.data.to){
                            this.$api.get(`contacts/:instance/${val.data.to}`).then(({data}) => {
                                this.contact = data
                                this.$store.commit('ADD_EXTERNAL_CONTACT', data)
								this.refresh = Date.now();
								if(val.data.message) {
									this.message = val.data.message
									this.replaceVariables();
								} else {
									this.message = val.data.message
								}
								this.loaded = true;
                            }).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
                            console.log('not found')
                        }
                    } else if(typeof val.data.to == 'object') {
                        val.data.to.forEach(id => {
                            let index = this.searchContacts.findIndex(x => x.id == id)
                            if (index != -1) {
                                this.contacts.push(this.searchContacts[index])
								if(val.data.message) {
									this.message = val.data.message
									this.replaceVariables();
								} else {
									this.message = val.data.message
								}
								this.loaded = true;
                            } else {
								this.$api.get(`contacts/:instance/${id}`).then(({data}) => {
									this.contacts.push(data)
									this.$store.commit('ADD_EXTERNAL_CONTACT', data)
									this.refresh = Date.now();
									if(val.data.message) {
										this.message = val.data.message
										this.replaceVariables();
									} else {
										this.message = val.data.message
									}
									this.loaded = true;
								}).catch(err => {
									if (!err || !err.response || !err.response.status || err.response.status !== 400) {
										this.$message.error(this.$err(err))
									}
								})
							}
                        })
                    }
                } else {
                    this.contact = {}
                    this.contacts = []
					this.loaded = false;
                }
            },
            deep:true
        }
    },
    methods:{
		replaceVariables(){
			if (this.contact.email && !this.contacts.length) {
				console.log('this contact', this.contact)
				if (this.message.includes('@Full Name')) {
					this.message = this.message.replaceAll('@Full Name', this.contact.firstName + ' ' + this.contact.lastName);
				}

				if (this.message.includes('@First Name') && this.contact.firstName) {
					this.message = this.message.replaceAll('@First Name', this.contact.firstName)
				} else {
					this.message = this.message.replaceAll('@First Name', '')
				}

				if (this.message.includes('@Last Name') && this.contact.lastName) {
					this.message = this.message.replaceAll('@Last Name', this.contact.lastName)
				} else {
					this.message = this.message.replaceAll('@Last Name', '')
				}

				if (this.message.includes('@Phone Number') && this.contact.phone) {
					this.message = this.message.replaceAll('@Phone Number', this.contact.phone)
				} else {
					this.message = this.message.replaceAll('@Phone Number', '')
				}

				if (this.message.includes('@Email Address') && this.contact.email) {
					this.message = this.message.replaceAll('@Email Address', this.contact.email)
				} else {
					this.message = this.message.replaceAll('@Email Address', '')
				}
			} else if (this.contacts && this.contacts.length) {
				console.log('this contact', this.contacts[0])
				if (this.message.includes('@Full Name')) {
					this.message = this.message.replaceAll('@Full Name', this.contacts[0].firstName + ' ' + this.contacts[0].lastName);
				}

				if (this.message.includes('@First Name') && this.contacts[0].firstName) {
					this.message = this.message.replaceAll('@First Name', this.contacts[0].firstName)
				} else {
					this.message = this.message.replaceAll('@First Name', '')
				}

				if (this.message.includes('@Last Name') && this.contacts[0].lastName) {
					this.message = this.message.replaceAll('@Last Name', this.contacts[0].lastName)
				} else {
					this.message = this.message.replaceAll('@Last Name', '')
				}

				if (this.message.includes('@Phone Number') && this.contacts[0].phone) {
					this.message = this.message.replaceAll('@Phone Number', this.contacts[0].phone)
				} else {
					this.message = this.message.replaceAll('@Phone Number', '')
				}

				if (this.message.includes('@Email Address') && this.contacts[0].email) {
					this.message = this.message.replaceAll('@Email Address', this.contacts[0].email)
				} else {
					this.message = this.message.replaceAll('@Email Address', '')
				}
			}
		},
		fileSize(size){
            //kb size convert to mb and gb
			let kb = size;
			let mb = size / 1024;
			let gb = size / 1024 / 1024;
			if(kb < 1) {
				return '1 KB'
			} else if(kb < 1024) {
				return kb.toFixed(2) + ' KB';
			} else if(mb < 1024) {
				return mb.toFixed(2) + ' MB';
			} else if(gb < 1024) {
				return gb.toFixed(2) + ' GB';
			}
        },
        getSocials(array) {
            let social = array.filter(x => {
                if (x.type) return x
            })
            return social
        },
        onClose(){
            this.$store.commit('CLOSE_PREVIEW_EMAIL')
        },
        getLink(type) {
            let socials = this.account.socials.map(x => x = x.type)
            if (socials.includes(type)) {
                let index = this.account.socials.findIndex(x => x.type == type)
                return this.account.socials[index].link
            }
        },
        sendEmail(testEmail = false){
            console.log('this contact', this.contact, this.contacts)
            console.log('preview email', this.previewEmail)
            let socials = this.account.socials.map(x => x = x.type)
            let mjml = ''
            if (this.account.template == '1') {
                mjml = `
					<mjml>
						<mj-body>
							<mj-section>
								<mj-column>
									<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
										<div>${this.message}</div>
									</mj-text>
								</mj-column>
							</mj-section>
				`
				if (this.previewEmail && this.previewEmail.data && this.previewEmail.data.showSignature){
					mjml += `
							<mj-section>
								<mj-column width="40%">
									${this.account.logo && this.account.logo != '' ? `
									<mj-image src="${this.account.logo}"></mj-image>`:''}
									<mj-column width="100%">
										<mj-social icon-padding="2px">
											${socials.includes('Facebook') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/facebook_icon_1f824839f3.png" href="${this.getLink('Facebook')}"></mj-social-element>`:''}
											${socials.includes('Instagram') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/instagram_icon_8eb7fba8c2.png" href="${this.getLink('Instagram')}"></mj-social-element>` : ''}
											${socials.includes('Twitter') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/twitter_logo_email_2343518957.png" href="${this.getLink('Twitter')}"></mj-social-element>` : ''}
											${socials.includes('LinkedIn') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/linkedin_icon_6fd5b99eca.png" href="${this.getLink('LinkedIn')}"></mj-social-element>` : ''}
											${socials.includes('YouTube') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/youtube_c77a285d7a.png" href="${this.getLink('YouTube')}"></mj-social-element>`:''}
											${socials.includes('Google+') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/google_plus_2082ce542c.png" href="${this.getLink('Google+')}"></mj-social-element>` : ''}
											${socials.includes('Pinterest') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/pinterest_30db360395.png" href="${this.getLink('Pinterest')}"></mj-social-element>` : ''}
											${socials.includes('WeChat') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/wechat_c566d59358.png" href="${this.getLink('WeChat')}"></mj-social-element>` : ''}
											${socials.includes('Quora') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/quora_2fb387fc08.png" href="${this.getLink('Quora')}"></mj-social-element>`:''}
											${socials.includes('Foursquare') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/foursquare_54e2bbc3e4.png" href="${this.getLink('Foursquare')}"></mj-social-element>` : ''}
											${socials.includes('Klout') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/klout_d89258e142.png" href="${this.getLink('Klout')}"></mj-social-element>` : ''}
										</mj-social>
									</mj-column>
									${this.account.appointment && this.settings && this.settings.options && this.settings.options.appointments && this.settings.options.appointments.widgetUrl && this.vendor && this.vendor.active ? `<mj-button background-color="${this.account.appointmentBgColor}" color="${this.account.appointmentColor}" href="${this.settings.options.appointments.widgetUrl}?userId=${this.vendor.user.id}">${this.account.appointmentText}</mj-button>` : ''}
								</mj-column>
								<mj-column width="60%">
									<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
										<strong style="font-size:17px">${this.account.name.toUpperCase()}</strong>
										${this.account.jobTitle && this.account.jobTitle != '' ? `<div>${this.account.jobTitle}</div>`:''}
										${this.account.company && this.account.company != '' ? `<div>${this.account.company}</div>`:''}
									</mj-text>
									<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
										${this.account && (this.account.email != '' || this.account.address != '' || this.account.website != '') ?
											`
											<table style="width:100%">
												${this.account.email != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Email: </td><td>${this.account.email}</td></tr>` : ''}
												${this.account.address != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Address: </td><td>${this.account.address}</td></tr>` : ''}
												${this.account.website != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Website: </td><td>${this.account.website}</td></tr>` : ''}
											</table>` : ''
										}
									</mj-text>
									<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
										${this.account && ((this.account.phone && this.account.phone.value != '') || this.account.officePhone != '' || this.account.fax != '') ?
											`
											<table style="width:100%">
												${this.account.phone.value != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">${this.account.phone.type}: </td><td>${this.account.phone.value}</td></tr>` : ''}
												${this.account.officePhone != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Office: </td><td>${this.account.officePhone}</td></tr>` : ''}
												${this.account.fax != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Fax: </td><td>${this.account.fax}</td></tr>` : ''}
											</table>` : ''
										}
									</mj-text>
								</mj-column>
							</mj-section>
						</mj-body>
					</mjml>
					`
				} else {
					mjml += `
						</mj-body>
					</mjml>
					`
				}
            } else if (this.account.template == '2') {
                mjml = `
					<mjml>
						<mj-body>
							<mj-section>
								<mj-column>
									<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
										<div>${this.message}</div>
									</mj-text>
								</mj-column>
							</mj-section>
				`
				if (this.previewEmail && this.previewEmail.data && this.previewEmail.data.showSignature){
					mjml += `
							<mj-section padding-bottom="0px">
								<mj-column width="100%">
									<mj-column width="40%" align="center">
										${this.account.logo && this.account.logo != '' ? `<mj-image src="${this.account.logo}"></mj-image>`:''}
									</mj-column>
									<mj-text align="center" line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
										<strong>${this.account.name.toUpperCase()}</strong>
										${this.account.jobTitle && this.account.jobTitle != '' ? `<div>${this.account.jobTitle}</div>`:''}
										${this.account.company && this.account.company != '' ? `<div>${this.account.company}</div>`:''}
									</mj-text>
									${this.account.appointment && this.settings && this.settings.options && this.settings.options.appointments && this.settings.options.appointments.widgetUrl && this.vendor && this.vendor.active ? `<mj-button background-color="${this.account.appointmentBgColor}" color="${this.account.appointmentColor}" href="${this.settings.options.appointments.widgetUrl}?userId=${this.vendor.user.id}">${this.account.appointmentText}</mj-button>` : ''}
								</mj-column>
							</mj-section>
							<mj-section padding-top="0px">
								<mj-column width="65%">
									<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
										${this.account && (this.account.email != '' || this.account.address != '' || this.account.website != '') ?
											`
											<table style="width:100%">
												${this.account.email != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Email: </td><td>${this.account.email}</td></tr>` : ''}
												${this.account.address != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Address: </td><td>${this.account.address}</td></tr>` : ''}
												${this.account.website != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Website: </td><td>${this.account.website}</td></tr>` : ''}
											</table>` : ''
										}
									</mj-text>
									<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
										${this.account && ((this.account.phone && this.account.phone.value != '') || this.account.officePhone != '' || this.account.fax != '') ?
											`
											<table style="width:100%">
												${this.account.phone.value != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">${this.account.phone.type}: </td><td>${this.account.phone.value}</td></tr>` : ''}
												${this.account.officePhone != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Office: </td><td>${this.account.officePhone}</td></tr>` : ''}
												${this.account.fax != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Fax: </td><td>${this.account.fax}</td></tr>` : ''}
											</table>` : ''
										}
									</mj-text>
								</mj-column>
								<mj-column width="35%">
									<mj-column width="100%">
										<mj-social icon-padding="2px">
											${socials.includes('Facebook') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/facebook_icon_1f824839f3.png" href="${this.getLink('Facebook')}"></mj-social-element>`:''}
											${socials.includes('Instagram') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/instagram_icon_8eb7fba8c2.png" href="${this.getLink('Instagram')}"></mj-social-element>` : ''}
											${socials.includes('Twitter') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/twitter_logo_email_2343518957.png" href="${this.getLink('Twitter')}"></mj-social-element>` : ''}
											${socials.includes('LinkedIn') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/linkedin_icon_6fd5b99eca.png" href="${this.getLink('LinkedIn')}"></mj-social-element>` : ''}
											${socials.includes('YouTube') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/youtube_c77a285d7a.png" href="${this.getLink('YouTube')}"></mj-social-element>`:''}
											${socials.includes('Google+') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/google_plus_2082ce542c.png" href="${this.getLink('Google+')}"></mj-social-element>` : ''}
											${socials.includes('Pinterest') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/pinterest_30db360395.png" href="${this.getLink('Pinterest')}"></mj-social-element>` : ''}
											${socials.includes('WeChat') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/wechat_c566d59358.png" href="${this.getLink('WeChat')}"></mj-social-element>` : ''}
											${socials.includes('Quora') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/quora_2fb387fc08.png" href="${this.getLink('Quora')}"></mj-social-element>`:''}
											${socials.includes('Foursquare') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/foursquare_54e2bbc3e4.png" href="${this.getLink('Foursquare')}"></mj-social-element>` : ''}
											${socials.includes('Klout') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/klout_d89258e142.png" href="${this.getLink('Klout')}"></mj-social-element>` : ''}
										</mj-social>
									</mj-column>
								</mj-column>
							</mj-section>
						</mj-body>
					</mjml>
					`
				} else {
					mjml += `
						</mj-body>
					</mjml>
					`
				}
			} else if (this.account.template == '3') {
                mjml = `
					<mjml>
						<mj-body>
							<mj-section>
								<mj-column>
									<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
										<div>${this.message}</div>
									</mj-text>
								</mj-column>
							</mj-section>
				`
				if (this.previewEmail && this.previewEmail.data && this.previewEmail.data.showSignature){
					mjml += `
							<mj-section>
								<mj-column width="40%">
									${this.account.logo && this.account.logo != '' ? `<mj-image src="${this.account.logo}"></mj-image>`:''}
									<mj-text align="center" line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
										<strong>${this.account.name.toUpperCase()}</strong>
										${this.account.jobTitle && this.account.jobTitle != '' ? `<div>${this.account.jobTitle}</div>`:''}
										${this.account.company && this.account.company != '' ? `<div>${this.account.company}</div>`:''}
									</mj-text>
									${this.account.appointment && this.settings && this.settings.options && this.settings.options.appointments && this.settings.options.appointments.widgetUrl && this.vendor && this.vendor.active ? `<mj-button background-color="${this.account.appointmentBgColor}" color="${this.account.appointmentColor}" href="${this.settings.options.appointments.widgetUrl}?userId=${this.vendor.user.id}">${this.account.appointmentText}</mj-button>` : ''}
								</mj-column>
								<mj-column width="60%">
									<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
										${this.account && (this.account.email != '' || this.account.address != '' || this.account.website != '') ?
											`
											<table style="width:100%">
												${this.account.email != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Email: </td><td>${this.account.email}</td></tr>` : ''}
												${this.account.address != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Address: </td><td>${this.account.address}</td></tr>` : ''}
												${this.account.website != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Website: </td><td>${this.account.website}</td></tr>` : ''}
											</table>` : ''
										}
									</mj-text>
									<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
										${this.account && ((this.account.phone && this.account.phone.value != '') || this.account.officePhone != '' || this.account.fax != '') ?
											`
											<table style="width:100%">
												${this.account.phone.value != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">${this.account.phone.type}: </td><td>${this.account.phone.value}</td></tr>` : ''}
												${this.account.officePhone != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Office: </td><td>${this.account.officePhone}</td></tr>` : ''}
												${this.account.fax != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Fax: </td><td>${this.account.fax}</td></tr>` : ''}
											</table>` : ''
										}
									</mj-text>
									<mj-social icon-padding="2px" align="right">
										${socials.includes('Facebook') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/facebook_icon_1f824839f3.png" href="${this.getLink('Facebook')}"></mj-social-element>`:''}
										${socials.includes('Instagram') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/instagram_icon_8eb7fba8c2.png" href="${this.getLink('Instagram')}"></mj-social-element>` : ''}
										${socials.includes('Twitter') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/twitter_logo_email_2343518957.png" href="${this.getLink('Twitter')}"></mj-social-element>` : ''}
										${socials.includes('LinkedIn') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/linkedin_icon_6fd5b99eca.png" href="${this.getLink('LinkedIn')}"></mj-social-element>` : ''}
										${socials.includes('YouTube') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/youtube_c77a285d7a.png" href="${this.getLink('YouTube')}"></mj-social-element>`:''}
										${socials.includes('Google+') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/google_plus_2082ce542c.png" href="${this.getLink('Google+')}"></mj-social-element>` : ''}
										${socials.includes('Pinterest') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/pinterest_30db360395.png" href="${this.getLink('Pinterest')}"></mj-social-element>` : ''}
										${socials.includes('WeChat') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/wechat_c566d59358.png" href="${this.getLink('WeChat')}"></mj-social-element>` : ''}
										${socials.includes('Quora') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/quora_2fb387fc08.png" href="${this.getLink('Quora')}"></mj-social-element>`:''}
										${socials.includes('Foursquare') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/foursquare_54e2bbc3e4.png" href="${this.getLink('Foursquare')}"></mj-social-element>` : ''}
										${socials.includes('Klout') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/klout_d89258e142.png" href="${this.getLink('Klout')}"></mj-social-element>` : ''}
									</mj-social>
								</mj-column>
							</mj-section>
						</mj-body>
					</mjml>
					`
				} else {
					mjml += `
						</mj-body>
					</mjml>
					`
				}
            } else if (this.account.template == '4') {
                mjml = `
					<mjml>
						<mj-body>
							<mj-section>
								<mj-column>
									<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
										<div>${this.message}</div>
									</mj-text>
								</mj-column>
							</mj-section>
				`
				if (this.previewEmail && this.previewEmail.data && this.previewEmail.data.showSignature){
					mjml += `
							<mj-section>
								<mj-column width="65%">
									<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
										<strong>${this.account.name.toUpperCase()}</strong>
										${this.account.jobTitle && this.account.jobTitle != '' ? `<div>${this.account.jobTitle}</div>`:''}
										${this.account.company && this.account.company != '' ? `<div>${this.account.company}</div>`:''}
									</mj-text>
									<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
										${this.account && (this.account.email != '' || this.account.address != '' || this.account.website != '') ?
											`
											<table style="width:100%">
												${this.account.email != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Email: </td><td>${this.account.email}</td></tr>` : ''}
												${this.account.address != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Address: </td><td>${this.account.address}</td></tr>` : ''}
												${this.account.website != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Website: </td><td>${this.account.website}</td></tr>` : ''}
											</table>` : ''
										}
									</mj-text>
									<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
										${this.account && ((this.account.phone && this.account.phone.value != '') || this.account.officePhone != '' || this.account.fax != '') ?
											`
											<table style="width:100%">
												${this.account.phone.value != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">${this.account.phone.type}: </td><td>${this.account.phone.value}</td></tr>` : ''}
												${this.account.officePhone != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Office: </td><td>${this.account.officePhone}</td></tr>` : ''}
												${this.account.fax != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Fax: </td><td>${this.account.fax}</td></tr>` : ''}
											</table>` : ''
										}
									</mj-text>
									${this.account.appointment && this.settings && this.settings.options && this.settings.options.appointments && this.settings.options.appointments.widgetUrl && this.vendor && this.vendor.active ? `<mj-button align="left" background-color="${this.account.appointmentBgColor}" color="${this.account.appointmentColor}" href="${this.settings.options.appointments.widgetUrl}?userId=${this.vendor.user.id}">${this.account.appointmentText}</mj-button>` : ''}
								</mj-column>
								<mj-column width="35%">
									<mj-column width="100%" align="center">
										${this.account.logo && this.account.logo != '' ? `<mj-image src="${this.account.logo}"></mj-image>`:''}
									</mj-column>
									<mj-social icon-padding="2px">
										${socials.includes('Facebook') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/facebook_icon_1f824839f3.png" href="${this.getLink('Facebook')}"></mj-social-element>`:''}
										${socials.includes('Instagram') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/instagram_icon_8eb7fba8c2.png" href="${this.getLink('Instagram')}"></mj-social-element>` : ''}
										${socials.includes('Twitter') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/twitter_logo_email_2343518957.png" href="${this.getLink('Twitter')}"></mj-social-element>` : ''}
										${socials.includes('LinkedIn') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/linkedin_icon_6fd5b99eca.png" href="${this.getLink('LinkedIn')}"></mj-social-element>` : ''}
										${socials.includes('YouTube') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/youtube_c77a285d7a.png" href="${this.getLink('YouTube')}"></mj-social-element>`:''}
										${socials.includes('Google+') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/google_plus_2082ce542c.png" href="${this.getLink('Google+')}"></mj-social-element>` : ''}
										${socials.includes('Pinterest') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/pinterest_30db360395.png" href="${this.getLink('Pinterest')}"></mj-social-element>` : ''}
										${socials.includes('WeChat') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/wechat_c566d59358.png" href="${this.getLink('WeChat')}"></mj-social-element>` : ''}
										${socials.includes('Quora') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/quora_2fb387fc08.png" href="${this.getLink('Quora')}"></mj-social-element>`:''}
										${socials.includes('Foursquare') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/foursquare_54e2bbc3e4.png" href="${this.getLink('Foursquare')}"></mj-social-element>` : ''}
										${socials.includes('Klout') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/klout_d89258e142.png" href="${this.getLink('Klout')}"></mj-social-element>` : ''}
									</mj-social>
								</mj-column>
							</mj-section>
						</mj-body>
					</mjml>
					`
				} else {
					mjml += `
						</mj-body>
					</mjml>
					`
				}
            } else if (this.account.template == '5') {
                mjml = `
					<mjml>
						<mj-body>
							<mj-section>
								<mj-column>
									<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
										<div>${this.message}</div>
									</mj-text>
								</mj-column>
							</mj-section>
				`
				if (this.previewEmail && this.previewEmail.data && this.previewEmail.data.showSignature){
					mjml += `
							<mj-section>
								<mj-column width="100%" align="left">
									<mj-column width="35%" align="left">
										<mj-column width="100%" align="left">
											${this.account.logo && this.account.logo != '' ? `<mj-image src="${this.account.logo}"></mj-image>`:''}
										</mj-column>
									</mj-column>
									<mj-column width="100%" align="left">
										<mj-social icon-padding="2px" align="left">
											${socials.includes('Facebook') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/facebook_icon_1f824839f3.png" href="${this.getLink('Facebook')}"></mj-social-element>`:''}
											${socials.includes('Instagram') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/instagram_icon_8eb7fba8c2.png" href="${this.getLink('Instagram')}"></mj-social-element>` : ''}
											${socials.includes('Twitter') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/twitter_logo_email_2343518957.png" href="${this.getLink('Twitter')}"></mj-social-element>` : ''}
											${socials.includes('LinkedIn') ? `<mj-social-element src="https://bildhive.nyc3.digitaloceanspaces.com/v3/linkedin_icon_6fd5b99eca.png" href="${this.getLink('LinkedIn')}"></mj-social-element>` : ''}
											${socials.includes('YouTube') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/youtube_c77a285d7a.png" href="${this.getLink('YouTube')}"></mj-social-element>`:''}
											${socials.includes('Google+') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/google_plus_2082ce542c.png" href="${this.getLink('Google+')}"></mj-social-element>` : ''}
											${socials.includes('Pinterest') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/pinterest_30db360395.png" href="${this.getLink('Pinterest')}"></mj-social-element>` : ''}
											${socials.includes('WeChat') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/wechat_c566d59358.png" href="${this.getLink('WeChat')}"></mj-social-element>` : ''}
											${socials.includes('Quora') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/quora_2fb387fc08.png" href="${this.getLink('Quora')}"></mj-social-element>`:''}
											${socials.includes('Foursquare') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/foursquare_54e2bbc3e4.png" href="${this.getLink('Foursquare')}"></mj-social-element>` : ''}
											${socials.includes('Klout') ? `<mj-social-element src="https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/klout_d89258e142.png" href="${this.getLink('Klout')}"></mj-social-element>` : ''}
										</mj-social>
									</mj-column>
								</mj-column>
								<mj-column width="100%" align="left">
									<mj-column width="65%" align="left">
										<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
											<strong>${this.account.name.toUpperCase()}</strong>
											${this.account.jobTitle && this.account.jobTitle != '' ? `<div>${this.account.jobTitle}</div>`:''}
											${this.account.company && this.account.company != '' ? `<div>${this.account.company}</div>`:''}
										</mj-text>
										<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
											${this.account && (this.account.email != '' || this.account.address != '' || this.account.website != '') ?
												`
												<table style="width:100%">
													${this.account.email != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Email: </td><td>${this.account.email}</td></tr>` : ''}
													${this.account.address != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Address: </td><td>${this.account.address}</td></tr>` : ''}
													${this.account.website != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Website: </td><td>${this.account.website}</td></tr>` : ''}
												</table>` : ''
											}
										</mj-text>
										<mj-text line-height="1.5" ${this.account.fontFamily != '' ? `font-family="${this.account.fontFamily}"` : ''}>
											${this.account && ((this.account.phone && this.account.phone.value != '') || this.account.officePhone != '' || this.account.fax != '') ?
												`
												<table style="width:100%">
													${this.account.phone.value != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">${this.account.phone.type}: </td><td>${this.account.phone.value}</td></tr>` : ''}
													${this.account.officePhone != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Office: </td><td>${this.account.officePhone}</td></tr>` : ''}
													${this.account.fax != '' ? `<tr><td style="color: ${this.account.fieldColor}; width: 65px; vertical-align: baseline;">Fax: </td><td>${this.account.fax}</td></tr>` : ''}
												</table>` : ''
											}
										</mj-text>
										${this.account.appointment && this.settings && this.settings.options && this.settings.options.appointments && this.settings.options.appointments.widgetUrl && this.vendor && this.vendor.active ? `<mj-button align="left" background-color="${this.account.appointmentBgColor}" color="${this.account.appointmentColor}" href="${this.settings.options.appointments.widgetUrl}?userId=${this.vendor.user.id}">${this.account.appointmentText}</mj-button>` : ''}
									</mj-column>
								</mj-column>
							</mj-section>
						</mj-body>
					</mjml>
					`
				} else {
					mjml += `
						</mj-body>
					</mjml>
					`
				}
            }


            let sendObj = {}
            sendObj.mjml = mjml
            sendObj.subject = this.previewEmail.data.subject
            sendObj.from = this.nylasAccount.email_address
            // sendObj.fileIds = this.previewEmail.data.attachments.map(x => x = x.id)
			sendObj.media = this.previewEmail.data.attachments.map(x => x = {url:x.url, filename:x.name})
            console.log('contact', this.contact.email, this.contacts)
            if (this.contact.email && !this.contacts.length) {
				if (testEmail) sendObj.to = this.user.email
                else sendObj.to = this.contact.email
            } else if (this.contacts && this.contacts.length) {
				if (testEmail) sendObj.to = this.user.email
                else sendObj.to = this.contacts.map(x => x = x.email)
            } else {
				this.$message.error('Contact not found! Please select a contact to send email.');
				return
			}
			sendObj.cc = this.previewEmail.data.cc
			sendObj.bcc = this.previewEmail.data.bcc
            console.log('sendObj', sendObj)
            this.$store.commit('LOAD',true)
            let url = `/services/email/send`
            if (this.vendor && this.vendor.active){
                url = `/contacts/:instance/sendmail`
                delete sendObj.from
            }
            this.$api.post(url, sendObj).then(({data}) =>{
                    console.log('data', data)
                    if (!testEmail){
						this.onClose()
                    	this.$emit('closemodal')
					}
                    this.$notification['success']({
                        message: `${testEmail ? 'Test ' : ''}Email Sent`,
                        description: 'Your email has been sent successfully.',
                        duration: 4
                    });
                })
                .catch( (err) => {
                    console.error('errr',err)
                    this.$message.error('Error sending email. Please try again.')
                })
                .finally(() => {
                    this.$store.commit('LOAD',false)
                })
        }
    }
}
</script>

<style type="text/css">
    @media only screen and (min-width:480px) {
      .mj-column-px-125 {
        width: 125px !important;
        max-width: 125px;
      }

      .mj-column-per-100 {
        width: 100% !important;
        max-width: 100%;
      }

      .mj-column-per-25 {
        width: 25% !important;
        max-width: 25%;
      }

      .mj-column-px-50 {
        width: 50px !important;
        max-width: 50px;
      }

      .mj-column-per-33-333333333333336 {
        width: 33.333333333333336% !important;
        max-width: 33.333333333333336%;
      }

      .mj-column-px-NaN {
        width: NaNauto !important;
        max-width: NaNauto;
      }
    }
  </style>
  <style media="screen and (min-width:480px)">
    .moz-text-html .mj-column-px-125 {
      width: 125px !important;
      max-width: 125px;
    }

    .moz-text-html .mj-column-per-100 {
      width: 100% !important;
      max-width: 100%;
    }

    .moz-text-html .mj-column-per-25 {
      width: 25% !important;
      max-width: 25%;
    }

    .moz-text-html .mj-column-px-50 {
      width: 50px !important;
      max-width: 50px;
    }

    .moz-text-html .mj-column-per-33-333333333333336 {
      width: 33.333333333333336% !important;
      max-width: 33.333333333333336%;
    }

    .moz-text-html .mj-column-px-NaN {
      width: NaNauto !important;
      max-width: NaNauto;
    }
  </style>
  <style type="text/css">
    @media only screen and (max-width:480px) {
      table.mj-full-width-mobile {
        width: 100% !important;
      }

      td.mj-full-width-mobile {
        width: auto !important;
      }
    }
  </style>
  <style lang="scss">
	.email-message p {
		margin: 0;
	}
  </style>
