<template>
    <div>
        <a-modal :width="'600px'" centered :header="null" :footer="null" :visible="statusModal.visible" @cancel="onClose" :zIndex="1001">
            <BHLoading :show="loading" />
            <h5>{{type == 'leadStatus' ? 'Add New Lead Status': type == 'leadSource' ? 'Add New Source': type == 'taskType' ? 'Add New Task Type' : type == 'dealSource' ? 'New Deal Source': type == 'customField' ? 'New Custom Field': type == 'lossReason' ? 'Add New Reason' : ''}}</h5>
            <div v-if="type=='leadStatus'">
                <a-form-model ref="newStatus" :model="newStatus">
                    <a-row :gutter="16" class="mt-4">
                        <a-col :span="24">
                            <a-form-model-item prop="name" label="Name" required :rules="req('Please enter the Name')">
                                <a-input v-model="newStatus.name" size="large" placeholder="Enter the Name"></a-input>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </a-form-model>
            </div>
            <div v-if="type == 'leadSource'">
                <a-form-model ref="newLeadSource" :model="newLeadSource">
                    <a-row :gutter="16" class="mt-4">
                        <a-col :span="24">
                            <a-form-model-item prop="name" label="Name" required :rules="req('Please enter the Name')">
                                <a-input v-model="newLeadSource.name" size="large" placeholder="Enter the Name"></a-input>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </a-form-model>
            </div>
            <div v-if="type == 'taskType'">
                <a-form-model ref="newTaskType" :model="newTaskType">
                    <a-row :gutter="16" class="mt-4">
                        <a-col :span="24">
                            <a-form-model-item prop="name" label="Name" required :rules="req('Please enter the Name')">
                                <a-input v-model="newTaskType.name" size="large" placeholder="Enter the Name"></a-input>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </a-form-model>
            </div>
            <div v-if="type == 'dealSource'">
                <a-form-model ref="newDealSource" :model="newDealSource">
                    <a-row :gutter="16" class="mt-4">
                        <a-col :span="24">
                            <a-form-model-item prop="name" label="Name" required :rules="req('Please enter the Name')">
                                <a-input v-model="newDealSource.name" size="large" placeholder="Enter the Name"></a-input>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </a-form-model>
            </div>
            <div v-if="type == 'dealStage'">
                <a-form-model ref="newDealStage" :model="newDealStage">
                    <a-row :gutter="16" class="mt-4">
                        <a-col :span="24">
                            <a-form-model-item prop="name" label="Name" required :rules="req('Please enter the Name')">
                                <a-input v-model="newDealStage.name" size="large" placeholder="Enter the Name"></a-input>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24" class="mt-3">
                            <a-form-model-item label="Color Label">
                                <div class="dF">
                                    <div @click="selectColor('var(--purple)')" style="margin: 0 5px 0 5px; width:40px; height:40px; background-color:var(--purple); text-align:center"><a-icon style="color:white" v-if="newDealStage.color === 'var(--purple)'" type="check" /></div>
                                    <div @click="selectColor('var(--orange)')" style="margin: 0 5px 0 5px; width:40px; height:40px; background-color:var(--orange); text-align:center"><a-icon style="color:white" v-if="newDealStage.color === 'var(--orange)'" type="check" /></div>
                                    <div @click="selectColor('var(--yellow)')" style="margin: 0 5px 0 5px; width:40px; height:40px; background-color:var(--yellow); text-align:center"><a-icon style="color:white" v-if="newDealStage.color === 'var(--yellow)'" type="check" /></div>
                                    <div @click="selectColor('var(--cyan)')" style="margin: 0 5px 0 5px; width:40px; height:40px; background-color:var(--cyan); text-align:center"><a-icon style="color:white" v-if="newDealStage.color === 'var(--cyan)'" type="check" /></div>
                                    <div @click="selectColor('var(--teal)')" style="margin: 0 5px 0 5px; width:40px; height:40px; background-color:var(--teal); text-align:center"><a-icon style="color:white" v-if="newDealStage.color === 'var(--teal)'" type="check" /></div>
                                    <div @click="selectColor('var(--danger)')" style="margin: 0 5px 0 5px; width:40px; height:40px; background-color:var(--danger); text-align:center"><a-icon style="color:white" v-if="newDealStage.color === 'var(--danger)'" type="check" /></div>
                                    <div @click="selectColor('#3F3356')" style="margin: 0 5px 0 5px; width:40px; height:40px; background-color:#3F3356; text-align:center"><a-icon style="color:white" v-if="newDealStage.color === '#3F3356'" type="check" /></div>
                                    <div @click="selectColor('var(--black)')" style="margin: 0 5px 0 5px; width:40px; height:40px; background-color:var(--black); text-align:center"><a-icon style="color:white" v-if="newDealStage.color === 'var(--black)'" type="check" /></div>
                                    <div @click="selectColor('var(--gray)')" style="margin: 0 5px 0 5px; width:40px; height:40px; background-color:var(--gray); text-align:center"><a-icon style="color:white" v-if="newDealStage.color === 'var(--gray)'" type="check" /></div>
                                </div>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </a-form-model>
            </div>
            <div v-if="type == 'customField'">
                <a-form-model :model="newField" ref="newField">
                    <a-row :gutter="16" class="mt-4">
                        <a-col :span="24">
                            <a-form-model-item label="Name" prop="name" requried :rules="req('Please enter the name')">
                                <a-input size="large" v-model="newField.name" placeholder="Enter the name" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-model-item prop="required">
                                <a-checkbox v-model="newField.required">Required</a-checkbox>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-model-item prop="type" label="Field Type" required :rules="req('Please choose a type for your field')">
                                <a-select @change="typeSelected" v-model="newField.type" size="large">
                                    <a-select-option v-for="(type) in types" :key="type.value">{{type.label}}</a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24" v-if="newField.type == 'multiplechoice'">
                            <a-form-model-item>
                                <a-checkbox @change="changeMultiple" v-model="multiple">Allow Multiple Answers</a-checkbox>
                            </a-form-model-item>
                        </a-col>
                         <a-col :span="24">
                            <template v-if="newField.type === 'multiplechoice' || newField.type === 'checkbox'">
                                <a-form-model-item v-for="(option, optionI) in options" :key="optionI" :label="`Options #${optionI+1}`">
                                    <div class="dF aC" style="justify-content:space-between">
                                        <a-input @change="changeOption(option,optionI)" v-model="option.text" class="flex-1"></a-input>

                                        <a-icon class="ml-2" v-if="optionI == options.length-1" @click="addOptions(options[options.length-1])" style="font-size:25px; color: var(--orange)" type="plus-circle" />
                                        <a-icon class="ml-2" v-if="options.length > 2 && optionI != options.length -1 " @click="options.splice(optionI, 1)" style="font-size:25px; color: var(--orange)" type="minus-circle" />
                                    </div>
                                </a-form-model-item>
                            </template>
                        </a-col>
                        <a-col :span="24" v-if="newField.type == 'text'">
                            <a-form-model-item prop="value" label="Default Value">
                                <a-input v-model="newField.value" size="large" placeholder="Enter the Default Value"></a-input>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24" v-else-if="newField.type == 'number'">
                            <a-form-model-item prop="value" label="Default Value">
                                <a-input type="number" v-model="newField.value" size="large" placeholder="Enter the Default Value" :default-value="'0'"></a-input>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24" v-else-if="newField.type === 'multiplechoice' || newField.type === 'checkbox'">
                            <a-form-model-item prop="value" label="Default Value">
                                <a-select v-model="newField.value" size="large" placeholder="Select a Default Value" :mode="newField.type == 'checkbox' ? '':newField.type == 'multiplechoice' && multiple ? 'multiple':''">
                                    <a-select-option v-for="(value, valueI) in filterOptions(options)" :key="valueI" :value="valueI">{{value.text}}</a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>

                        <a-col :span="24" v-else-if="newField.type === 'date'">
                            <a-form-model-item prop="value" label="Default Value">
                                <a-date-picker style="width:100%" v-model="newField.value" size="large" placeholder="Select a Default Value" />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </a-form-model>
            </div>
            <div v-if="type == 'lossReason'">
                <a-form-model ref="lossReason" :model="lossReason">
                    <a-row :gutter="16" class="mt-4">
                        <a-col :span="24">
                            <a-form-model-item prop="name" label="Name" required :rules="req('Please enter the Name')">
                                <a-input v-model="lossReason.name" size="large" placeholder="Enter the Name"></a-input>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </a-form-model>
            </div>
            <div class="mt-4 dF" style="justify-content:flex-end">
                <a-button @click="onClose" class="cancel-button" size="large">CANCEL</a-button>
                <a-button @click="submit" type="primary" size="large" class="ml-3">CREATE</a-button>
            </div>
        </a-modal>
    </div>
</template>

<script>
import moment from 'moment'
import BHLoading from 'bh-mod/components/common/Loading'
export default {
    props:{
        // type:{
        //     type:String,
        //     default:''
        // }
    },
    components:{
        BHLoading,
    },
    computed:{
        user(){
            return this.$store.state.user.user
        },
        statusModal() {
            return this.$store.state.contacts.leadStatusModal
        },
        type () {
            return this.$store.state.contacts.leadStatusModal.object.type || ''
        }
    },
    data() {
        return{
            multiple:false,
            loading:false,
            newStatus:{
                name:''
            },
            newLeadSource:{
                name:''
            },
            newTaskType:{
                name:''
            },
            newAppointmentType:{
                name:''
            },
            newDealSource:{
                name:''
            },
            lossReason:{
                name:''
            },
            newDealStage:{
                name:'',
                color:''
            },
            newField:{
                name:'',
                required:false,
                type:'text',
                value:''
            },
            types:[
                {value:'text', label:'Text'},
                {value:'number', label:'Number'},
                {value:'checkbox', label:'Check Box'},
                {value:'multiplechoice', label:'Multiple Choice'},
                {value:'date', label:'Date (MM/DD/YYYY)'}
            ],
            options:[{
                text:''
            }],
        }
    },
    methods:{
        moment,
        changeMultiple(e) {
            if (!e.target.checked && typeof this.newField.value == 'object' && this.newField.value.length > 1 && this.newField.type == 'multiplechoice') {
                this.newField.value = 0
            }
        },
        filterOptions(array) {
            array = array.filter(x => {
                if (x.text != '') {
                    return x
                }
            })
            return array
        },
        changeOption(text, index) {
            if (text.text.length == 0 && this.options.length > 2) {
                this.options.splice(index,1)
            }
        },
        addOptions(lastOption){
            if (!lastOption.text) return this.$message.error('You need to fill in the field before adding a new one')
            else {
                let option = {
                    text:''
                }
                this.options.push(option)
            }
        },
        typeSelected(e) {
            if (e == 'text') {
                this.newField.value = ''
            } else if (e == 'number') {
                this.newField.value = 0
            } else if (e == 'checkbox' || e == 'multiplechoice') {
                this.newField.value = []
            } else if (e == 'date'){
                this.newField.value = this.moment(Date.now())
            }
        },
        selectColor(color) {
            this.newDealStage.color = color
        },
        req:msg=>({required:true,message:msg}),
        onClose() {
            if (this.type == 'leadStatus' && this.$refs.newStatus) {
                this.$refs.newStatus.resetFields()
            } else if (this.type == 'leadSource' && this.$refs.newLeadSource) {
                this.$refs.newLeadSource.resetFields()
            } else if (this.type == 'taskType' && this.$refs.newTaskType) {
                this.$refs.newTaskType.resetFields()
            } else if (this.type == 'dealSource' && this.$refs.newDealSource) {
                this.$refs.newDealSource.resetFields()
            } else if (this.type == 'dealStage') {
                this.newDealStage.color = ''
				if(this.$refs.newDealStage) this.$refs.newDealStage.resetFields()
            } else if (this.type == 'customField') {
                this.options = [{ text:''}]
                this.multiple = false
				if(this.$refs.newField) this.$refs.newField.resetFields()
            } else if (this.type == 'lossReason' && this.$refs.lossReason) {
                this.$refs.lossReason.resetFields()
            }
            this.$store.commit('CLOSE_LEAD_STATUS')
        },
        submit() {
            console.log('type', this.type)
            if (this.type == 'leadStatus') {
                this.$refs.newStatus.validate(valid => {
                    if(valid) {
                        this.loading = true
                        this.$api.post('/lead-statuses/:instance', this.newStatus).then((response) => {
                            console.log('data', response)
                            let data = response
                            if (response.data) data = response.data
                            this.$store.commit('SET_PROP', {where:['statuses',data.id],what:data})
                            this.loading = false
                            this.onClose()
                        }).catch(err => {
							this.loading = false
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    }
                })
            } else if (this.type == 'leadSource') {
                this.$refs.newLeadSource.validate(valid => {
                    if (valid) {
                        let settings = JSON.parse(JSON.stringify(this.$store.state.contacts.allSettings.app))
                        let sendObj = JSON.parse(JSON.stringify(this.newLeadSource))
                        let contains = false
                        sendObj.id = Date.now().toString()
                        if (settings.options.leadSource && settings.options.leadSource.length != 0) {
                            // let contains = false
                            settings.options.leadSource.forEach(source => {
                                if (source.name.toLowerCase() == this.newLeadSource.name.toLowerCase()) {
                                    contains = true
                                    // return this.$message.error('Source already exists!')
                                }
                            })
                            if (!contains) {
                                settings.options.leadSource.push(sendObj)
                            }
                        }
                        else {
                            let sources = [
                                {name:'No Source', id:'nosource'},
                                {name:'Email', id:'email'},
                                {name:'Cold Call', id:'coldcall'},
                                {name:'Advertising', id:'advertising'}
                            ]
                            // let contains = false
                            sources.forEach(source => {
                                if (source.name.toLowerCase() == this.newLeadSource.name.toLowerCase()) {
                                    contains = true
                                    // return this.$message.error('Source already exists!')
                                }
                            })
                            if (!contains) {
                                settings.options.leadSource = sources
                                settings.options.leadSource.push(sendObj)
                            }
                        }
                        if (contains) {
                            return this.$message.error('Source already exists!')
                        } else {
                            this.loading = true
                            this.$api.post('/settings/:instance/contacts', settings).then( ({data}) => {
                                console.log('DATA COMING BACK', data)
                                this.$store.commit('UPDATE_ALLSETTINGS', data)
                                this.loading = false
                                this.onClose()
                            }).catch(err => {
								this.loading = false
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
                        }
                    }
                })
            } else if (this.type == 'taskType') {
                this.$refs.newTaskType.validate(valid => {
                    if (valid) {
                        let contains = false
                        let settings = JSON.parse(JSON.stringify(this.$store.state.contacts.allSettings.app))
                        let sendObj = JSON.parse(JSON.stringify(this.newTaskType))
                        sendObj.id = Date.now().toString()
                        if (settings.options.taskType && settings.options.taskType.length != 0) {
                            // let contains = false
                            settings.options.taskType.forEach(type => {
                                if (type.name.toLowerCase() == this.newTaskType.name.toLowerCase()) {
                                    contains = true
                                    // return this.$message.error('Type already exists!')
                                }
                            })
                            if (!contains) {
                                settings.options.taskType.push(sendObj)
                            }
                        }
                        else {
                            let types = [
                                {name:'To-do', id:'todo'},
                                {name:'Call', id:'call'},
                                {name:'Follow up', id:'followup'}
                            ]
                            // let contains = false
                            types.forEach(type => {
                                if (type.name.toLowerCase() == this.newTaskType.name.toLowerCase()) {
                                    contains = true
                                    // return this.$message.error('Type already exists!')
                                }
                            })
                            if (!contains) {
                                settings.options.taskType = types
                                settings.options.taskType.push(sendObj)
                            }
                        }
                        if (contains) {
                            return this.$message.error('Type already exists!')
                        }
                        else {
                            this.loading = true
                            this.$api.post('/settings/:instance/contacts', settings).then( ({data}) => {
                                console.log('DATA COMING BACK', data)
                                this.$store.commit('UPDATE_ALLSETTINGS', data)
                                this.loading = false
                                this.onClose()
                            }).catch(err => {
								this.loading = false
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
                        }

                    }
                })
            } else if (this.type == 'dealSource') {
                this.$refs.newDealSource.validate(valid => {
                    if (valid) {
                        let settings = JSON.parse(JSON.stringify(this.$store.state.contacts.allSettings.app))
                        let sendObj = JSON.parse(JSON.stringify(this.newDealSource))
                        let contains = false
                        sendObj.id = Date.now().toString()
                        if (settings.options.dealSources && settings.options.dealSources.length != 0) {
                            // let contains = false
                            settings.options.dealSources.forEach(source => {
                                if (source.name.toLowerCase() == this.newDealSource.name.toLowerCase()) {
                                    contains = true
                                    // return this.$message.error('Type already exists!')
                                }
                            })
                            if (!contains) {
                                settings.options.dealSources.push(sendObj)
                            }
                        }
                        else {
                            let sources = [
                                {name:'No Source', id:'nosource'},
                                {name:'Email', id:'email'},
                                {name:'Cold Call', id:'coldcall'},
                                {name:'Advertising', id:'advertising'}
                            ]
                            // let contains = false
                            sources.forEach(source => {
                                if (source.name.toLowerCase() == this.newDealSource.name.toLowerCase()) {
                                    contains = true
                                    // return this.$message.error('Type already exists!')
                                }
                            })
                            if (!contains) {
                                settings.options.dealSources = sources
                                settings.options.dealSources.push(sendObj)
                            }
                        }
                        if (contains) {
                            return this.$message.error('Source already exists!')
                        } else {
                            this.loading = true
                            this.$api.post('/settings/:instance/contacts', settings).then( ({data}) => {
                                console.log('DATA COMING BACK', data)
                                this.$store.commit('UPDATE_ALLSETTINGS', data)
                                this.loading = false
                                this.onClose()
                            }).catch(err => {
								this.loading = false
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
                        }
                    }
                })
            } else if (this.type == 'dealStage') {
                if (!this.newDealStage.color  || this.newDealStage == '') {
                    return this.$message.error('Please select a color')
                }
                this.$refs.newDealStage.validate(valid => {
                    if (valid) {
                        console.log('NEW STAGE', this.newDealStage)
                        let sendObj = JSON.parse(JSON.stringify(this.$store.state.contacts.allSettings.app))
                        sendObj.options.stages.list.push(this.newDealStage)

                        let won = sendObj.options.stages.list.findIndex(x => x.id == 'won')
                        let lost = sendObj.options.stages.list.findIndex(x => x.id == 'lost')
                        let transaction = sendObj.options.stages.list.findIndex(x => x.id == 'transaction')
                        let paidReservation = sendObj.options.stages.list.findIndex(x => x.id == 'paid_reservation')
                        let completedReservation = sendObj.options.stages.list.findIndex(x => x.id == 'completed_reservation')

                        let filteredList = sendObj.options.stages.list.filter(({id}) => {
                            return !['paid_reservation', 'completed_reservation', 'transaction', 'won', 'lost'].includes(id)
                        })
                        console.log('filteredList', filteredList)

                        for (var i = 0; i < filteredList.length; i++){
                            filteredList[i].id = `${i}`
                        }

                        filteredList.push(sendObj.options.stages.list[paidReservation])
                        filteredList.push(sendObj.options.stages.list[completedReservation])
                        filteredList.push(sendObj.options.stages.list[transaction])
                        filteredList.push(sendObj.options.stages.list[won])
                        filteredList.push(sendObj.options.stages.list[lost])

                        sendObj.options.stages.list = filteredList
                        this.loading = true
                        this.$api.post('/settings/:instance/contacts', sendObj).then( ({data}) => {
                            console.log('DATA COMING BACK', data)
                            this.$store.commit('UPDATE_ALLSETTINGS', data)
                            this.loading = false
                            this.onClose()
                        }).catch(err => {
							this.loading = false
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    }
                })
            } else if (this.type == 'customField') {
                this.$refs.newField.validate(valid => {
                    if(valid){
                        let sendObj = {name:'', options:{}, type:'', required:false, user:''}
                        if (this.newField.type === 'multiplechoice' || this.newField.type === 'checkbox') {
                            if (this.options.length == 1 || (this.options[1].text == '' && this.options.length == 2)){
                                return this.$message.error('Requires at least two Choices')
                            } else {
                                sendObj.name = this.newField.name
                                sendObj.type = this.newField.type
                                sendObj.required = this.newField.required
                                sendObj.options = {
                                    default:this.newField.value,
                                    multipleChoices:this.options,
                                    multipleAnswers:this.multiple,
                                }
                                sendObj.id = Date.now().toString()
                                sendObj.options.multipleChoices = sendObj.options.multipleChoices.map( x => x = x.text)
                                sendObj.user = this.user.id
                            }
                        } else if (this.newField.type == 'text' || this.newField.type == 'number') {
                            sendObj.id = Date.now().toString()
                            sendObj.name = this.newField.name
                            sendObj.type = this.newField.type
                            sendObj.required = this.newField.required
                            sendObj.options = {
                                default:this.newField.value,
                                multipleChoices:[],
                                multipleAnswers:false,
                            }
                            sendObj.user = this.user.id
                        } else if (this.newField.type == 'date') {
                            sendObj.id = Date.now().toString()
                            sendObj.name = this.newField.name
                            sendObj.type = this.newField.type
                            sendObj.required = this.newField.required
                            sendObj.options = {
                                default:this.moment(this.newField.value).format('X')*1000,
                                multipleChoices:[],
                                multipleAnswers:false,
                            }
                            sendObj.user = this.user.id
                        }
                        this.$api.post(`/custom-fields/:instance`, sendObj).then( ({data}) => {
                            this.$store.commit('SET_PROP', {where:['fields',data.id],what:data})
                            this.loading = false
                            this.onClose()
                        }).catch( err => {
                            this.$message.error(this.$err(err))
                            this.loading = false
                            this.onClose()
                        })
                    }
                })
            } else if (this.type == 'lossReason') {
                this.$refs.lossReason.validate(valid => {
                    if (valid) {
                        let settings = JSON.parse(JSON.stringify(this.$store.state.contacts.allSettings.app))
                        let sendObj = JSON.parse(JSON.stringify(this.lossReason))
                        sendObj.id = Date.now().toString()
                        if (!settings.options.lostReasons) {
                            settings.options.lostReasons = []
                            settings.options.lostReasons.push(sendObj)
                        } else {
                            settings.options.lostReasons.push(sendObj)
                        }
                        console.log('settings', settings)
                        this.loading = true
                        this.$api.post('/settings/:instance/contacts', settings).then( ({data}) => {
                            console.log('DATA COMING BACK', data)
                            this.$store.commit('UPDATE_ALLSETTINGS', data)
                            this.loading = false
                            this.onClose()
                        }).catch(err => {
							this.loading = false
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    }
                })
            }
        }
    }
}
</script>

<style>

</style>
