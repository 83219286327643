<template>
    <div>
        <a-modal
            :visible="contactModal.type === 'Task' && contactModal.visible"
            @cancel="cancelTask"
            :footer="null"
            :bodyStyle="{padding: '30px'}"
            width="800px"
            centered
            :zIndex="1001"
            >
            <BHLoading :show="loading" />
            <div>
                <h5 class="mb-5">Add New Task</h5>
                <a-form-model ref="newTask" :model="newTask">
                    <a-form-model-item label="Title" prop="title" required :rules="req('Please enter the title')">
                        <a-input v-model="newTask.title" size="large" placeholder="Task Title"></a-input>
                    </a-form-model-item>

                    <a-form-model-item label="Description" prop="description">
                        <a-textarea
                            v-model="newTask.description"
                            size="large"
                            :auto-size="{ minRows: 3, maxRows: 6 }"
                            placeholder="Description of task"
                            />
                    </a-form-model-item>
                    <a-form-model-item label="Task Type" prop="type" required :rules="req('Please select a type')">
                        <a-select size="large" v-model="newTask.type">
                            <div slot="dropdownRender" slot-scope="menu">
                                <v-nodes :vnodes="menu" />
                                <a-divider style="margin: 4px 0;" />
                                <div
                                    style="padding: 8px; cursor: pointer; color:var(--orange)"
                                    @mousedown="e => e.preventDefault()"
                                    @click="addNewType"
                                >
                                    <i style="font-size:15px;" class="fe fe-edit-2 mr-2"></i> Add New Task Type
                                </div>
                            </div>
                            <a-select-option v-for="(taskType) in taskTypes" :key="taskType.id ? taskType.id:taskType.value">{{taskType.name}}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="Priority Level" required>
                        <div class="dF">
                            <div @click="selectPriority('high')" :class="newTask.priority && newTask.priority =='high' ? 'select-high':'high-priority'">HIGH PRIORITY</div>
                            <div @click="selectPriority('medium')" :class="newTask.priority && newTask.priority =='medium' ? 'select-med':'med-priority'">MED PRIORITY</div>
                            <div @click="selectPriority('low')" :class="newTask.priority && newTask.priority =='low' ? 'select-low':'low-priority'">LOW PRIORITY</div>
                        </div>
                    </a-form-model-item>

                    <!-- <a-form-model-item label="Type" required>
                        <a-select size="large" v-model="type">
                            <a-select-option value="contact">
                                Contact
                            </a-select-option>
                            <a-select-option value="opportunity">
                                Opportunity
                            </a-select-option>
                        </a-select>
                    </a-form-model-item> -->

                    <a-form-model-item prop="assignTo" label="Assign To" :rules="req('Please assign this task to a user')">
                        <a-select
                            v-model="newTask.assignTo"
                            mode="multiple"
                            placeholder="Type to search..."
                            size="large"
                            :filter-option="filterOption"
                            :not-found-content="null"
                            @search="searchAssign">
                            <a-select-option v-for="user in userSource" :key="user.id" :value="user.id">
                                {{ user.firstName + ' ' + user.lastName }} ({{ user.email }})
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <a-form-model ref="relatedTo" :model="relatedTo">
                        <a-form-model-item prop="type" label="What is this task related to?" :rules="req('Please select one of the options below')">
                            <a-select @change="selectType" v-model="relatedTo.type" size="large">
                                <a-select-option value="contact">Contact</a-select-option>
                                <a-select-option value="opportunity">Opportunity</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-form-model>

                    <template v-if="relatedTo.type == 'contact'">
                        <div v-if="leadTask == ''">
                            <a-form-model-item prop="contact" label="Primary Contact" :rules="relatedTo.type == 'contact' ?req('Please enter the contact name'):{}">
                                <searchContact v-if="contactModal.type === 'Task' && contactModal.visible" @updatecontact="updatePerson" />
                            </a-form-model-item>
                        </div>
                        <div v-else>
                            <div class="dF aC" style="line-height: 26.5px; padding-top: 5px; font-size: 14px; color: #40454c;">
                                <div class="dF mr-3"><div>Primary Contact</div>&nbsp;<div style="color:var(--danger)">*</div></div>
                                <i class="fe fe-lock" />
                            </div>
                            <a-form-model-item>
                                <a-input size="large" :default-value="defaultName" disabled />
                            </a-form-model-item>
                        </div>
                    </template>
                    <template v-else>
                        <div v-if="opportunityTask != ''">
                            <div class="dF aC" style="line-height: 26.5px; padding-top: 5px; font-size: 14px; color: #40454c;">
                                <div class="dF mr-3"><div>Related To</div>&nbsp;<div style="color:var(--danger)">*</div></div>
                                <i class="fe fe-lock" />
                            </div>
                            <a-form-model-item>
                                <a-input size="large" :default-value="defaultName" disabled />
                            </a-form-model-item>
                        </div>
                        <div v-else>
                            <a-form-model-item prop="opportunity" label="Related To" :rules="relatedTo.type == 'opportunity' ?req('Please enter the opportunity name'):{}">
                                <a-select
                                    v-model="newTask.opportunity"
                                    mode="single"
                                    show-search
                                    :show-arrow="false"
                                    placeholder="Type to search..."
                                    size="large"
                                    :not-found-content="null"
                                    :filter-option="filterOpportunity"
                                    @search="searchOpportunity">
                                    <a-select-option v-for="opp in opportunitySource" :key="opp.id" :value="opp.id">
                                        {{ opp.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </div>
                    </template>

                    <!-- <div v-show="type === 'opportunity'">
                        <a-form-model-item label="Related To" prop="opportunity" :rules="type === 'opportunity'?req('Please enter the opportunity name'):{}">
                            <a-select
                                v-model="newTask.opportunity"
                                mode="single"
                                show-search
                                :show-arrow="false"
                                placeholder="Type to search..."
                                size="large"
                                :not-found-content="null"
                                :filter-option="filterOption2"
                                @search="searchAssign2">
                                <a-select-option v-for="opp in opportunitySource" :key="opp.id" :value="opp.id">
                                    {{ opp.name }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </div> -->

                    <a-form-model-item label="Due Date" prop="dueDate">
                        <a-date-picker size="large" v-model="dateHolder" @change="onChange" style="width:100%"></a-date-picker>
                    </a-form-model-item>
                </a-form-model>
                <div class="dF" style="justify-content:flex-end">
                    <a-button @click="cancelTask" size="large" class="mr-3 cancel-button">CANCEL</a-button>
                    <a-button @click="createTask" size="large" type="primary">CREATE</a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
import moment from 'moment'
import BHLoading from 'bh-mod/components/common/Loading'
import searchContact from '@/components/common/searchContact'
    export default {
        components:{
            BHLoading,searchContact,
            VNodes: {
            functional: true,
            render: (h, ctx) => ctx.props.vnodes,
            },
        },
        data(){
            return {
                defaultName:'',
                newTask:{
                    title:'',
                    description:'',
                    priority:'',
                    contact:'',
                    opportunity:'',
                    assignTo:[],
                    dueDate:'',
                    completed:false,
                    type:''
                },
                type:'contact',
                relatedTo:{
                    type:'contact'
                },
                dateHolder:'',
                userSource:[],
                opportunitySource:[],
                loading:false,
            }
        },
        watch:{
            duplicateTask: {
                handler(val){
                    console.log('VAL', val)
                    this.newTask.description = val.data.description
                    this.newTask.priority = val.data.priority
                    this.newTask.title = val.data.title
                    let list = []
                    val.data.assignTo.forEach( x => {
                        list.push(x.id)
                    })
                    this.userSource = this.users.filter(({id}) => {
                        return list.includes(id)
                    })
                    console.log('USER SOURCEEEE', this.userSource)
                    console.log('LISTTT', list)
                    this.newTask.assignTo = list
                    console.log('THIS NEW TASK', this.newTask)
                },
                deep: true
            },
            changeTypeTime(){
                console.log('CHANGE TPYE TIME WATCH RAN')
                this.type = 'opportunity'
                this.relatedTo.type = 'opportunity'
                this.newTask.opportunity = this.changeType.opportunity.id
                this.opportunitySource = this.opportunities.filter(({id}) => {
                    return id == this.newTask.opportunity
                })
            },
            visible:{
                handler(val) {
                    if (!val) {
                        this.$store.commit('CLEAR_LEAD_ACTION')
                        this.$store.commit('CLEAR_OPPORTUNITY_ACTION')
                        this.type = 'contact'
                        this.relatedTo.type = 'contact'
                    }
                }
            },
            leadTask:{
                handler(val) {
                    if (val != '' && this.contactModal.type == 'Task') {

                        this.newTask.contact = val
                        if (this.$store.state.contacts.allContacts[val] && this.$store.state.contacts.allContacts) {
                            let find = this.$store.state.contacts.allContacts[val]
                            if(find && find.firstName && find.lastName && find.firstName.trim() && find.lastName.trim()) this.defaultName = `${find.firstName} ${find.lastName}`
                            else if (find) this.defaultName = `${find.email}`
                        } else if (this.$store.state.contacts.externalContacts && this.$store.state.contacts.externalContacts[val]) {
                            let find = this.$store.state.contacts.externalContacts[val]
                            if(find && find.firstName && find.lastName && find.firstName.trim() && find.lastName.trim()) this.defaultName = `${find.firstName} ${find.lastName}`
                            else if (find) this.defaultName = `${find.email}`
                        }
                    }
                }
            },
            opportunityTask:{
                handler(val) {
                    if (val != '' && this.contactModal.type == 'Task') {
                        this.type = 'opportunity'
                        this.relatedTo.type = 'opportunity'
                        console.log('opptask',val)
                        this.newTask.opportunity = val
                        if (this.$store.state.contacts.opportunities && this.$store.state.contacts.opportunities.find(x => x.id == val)) {
                            let find = this.$store.state.contacts.opportunities.find(x => x.id == val)
                            this.defaultName = `${find.name}`
                        }
                    }
                }
            }
        },
        computed:{
            opportunityTask() {
                return this.$store.state.contacts.opportunityAction
            },
            leadTask() {
                return this.$store.state.contacts.leadAction
            },
            visible() {
                return this.$store.state.addNew.visible
            },
            taskTypes() {
                if (this.$store.state.contacts.allSettings && this.$store.state.contacts.allSettings.app && this.$store.state.contacts.allSettings.app.options && this.$store.state.contacts.allSettings.app.options.taskType) {
                    return this.$store.state.contacts.allSettings.app.options.taskType
                } else {
                    return [
                        {name:'To-do', id:'todo'},
                        {name:'Call', id:'call'},
                        {name:'Follow up', id:'followup'}
                    ]
                }
            },
            changeTypeTime(){
                return this.$store.state.contacts.changeType.time
            },
            changeType(){
                return this.$store.state.contacts.changeType
            },
            duplicateTask(){
                return this.$store.state.duplicate
            },
            users(){
                return this.$store.state.contacts.users
            },
            contacts(){
                return Object.values(this.$store.state.contacts.allContacts)
            },
            instance(){
                return this.$store.state.instance
            },
            contactModal(){
                return this.$store.state.addNew
            },
            currentUser(){
                console.log('CURRENT USER', this.$store.state.user)
                return this.$store.state.user
            },
            opportunities(){
                // return this.$store.state.contacts.opportunities
                let opportunities = JSON.parse(JSON.stringify(this.$store.state.contacts.opportunities))
                opportunities = opportunities.filter(x => {
                    if (x.visibility != 'everyone' && x.visibility != null){
                        let userFound = false
                        x.owners.forEach(y => {
                            if (y.id == this.currentUser.user.id) userFound = true
                        })
                        return userFound || this.$p == 50
                    } else {
                        return true
                    }
                })
                console.log('OPPORTUNITIES', opportunities)
                return opportunities
            },
        },
        methods:{
            selectType(e) {
                this.type = e
            },
            filterOpportunity(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },
            searchOpportunity(input){
                if(input && input.length>1) {
                    this.opportunitySource = this.opportunities.filter(({name}) => {
                        name = name.toLowerCase()
                        input = input.toLowerCase()
                        return name.includes(input)
                    })
                } else {
                    return this.opportunitySource = []
                }
            },
            addNewType(){
                console.log('addnewtype')
                this.$store.commit('OPEN_LEAD_STATUS', {type:'taskType'})
            },
            moment,
            updatePerson(contact){
                console.log('UPDATE PERSON RAN', contact)
				this.$set(this.newTask, 'contact', contact)
                this.$refs.newTask.validate(valid => {
                    if (valid) {console.log('finish')}
                })
            },
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },
            filterOption2(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },
            onChange(value, dateString){
                console.log('VALUEEEE', value)
                console.log('DATESTRINGGGG', dateString)
                this.newTask.dueDate = parseInt(this.moment(dateString).format('X'))*1000
                console.log('NEW TASK DUE DATE', this.newTask.dueDate)
            },
            req:msg=>({required:true,message:msg}),
            searchAssign(input){
                // console.log('this is the input', this.users)
                if(input && input.length>1 && this.users.length) {
                    this.users.forEach(x => {
                        x.name = `${x.firstName && x.firstName != '' ? x.firstName : ''} ${x.lastName && x.lastName != '' ? x.lastName : ''}`
                    })
                    this.userSource = this.users.filter(({name}) => {
                        name = name.toLowerCase()
                        input = input.toLowerCase()
                        return name.includes(input)
                    })
                } else {
                    return this.userSource = []
                }
            },
            searchAssign2(input){
                if(input && input.length>1) {
                    this.opportunitySource = this.opportunities.filter(({name}) => {
                        name = name.toLowerCase()
                        input = input.toLowerCase()
                        return name.includes(input)
                    })
                } else {
                    return this.opportunitySource = []
                }
            },
            selectPriority(item){
                this.newTask.priority = item
            },
            createTask(){
                if (!this.newTask.priority.trim()) return this.$message.error('Please select a priority for this task!')
                this.$refs.newTask.validate(valid => {
                    if (valid) {
                        this.loading = true
                        let list = []
                        this.newTask.assignTo.forEach(a => {
                            let index = list.findIndex(x => x == a)
                            if (index == -1) {
                                list.push(a)
                            }
                        })

                        let sendObj = JSON.parse(JSON.stringify(this.newTask))
                        sendObj.assignTo = list

                        if (sendObj.dueDate == '' || sendObj.dueDate == null) sendObj.dueDate = 0
                        console.log('sendOjb', sendObj)

                        if (this.relatedTo.type == 'contact'){
                            console.log('CONTACT PUT REQUEST RAN')
                            delete sendObj.opportunity
                            sendObj.relatedType = 'contact'
                            this.$api.post(`/tasks/:instance`,sendObj).then( ({data}) => {
                                console.log('DATAA COMING BACK', data)
                                this.loading = false
                                this.$store.commit('ADD_NEW_TASK', data)
                                this.cancelTask()
                            }).catch(err => {
								this.loading = false
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
                        } else if (this.relatedTo.type == 'opportunity'){
                            console.log('OPPORTUNITY PUT REQUEST RAN')
                            delete sendObj.contact
                            sendObj.relatedType = 'opportunity'
                            this.$api.post(`/tasks/:instance`,sendObj).then( ({data}) => {
                                console.log('DATAA COMING BACK', data)
                                this.loading = false
                                this.$store.commit('ADD_NEW_TASK', data)
                                this.cancelTask()
                            }).catch(err => {
								this.loading = false
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
                        }

                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            cancelTask(){
                // this.$refs['newTask'].resetFields();
                this.newTask.priority = ''
                this.newTask.description = ''
                this.newTask.title = ''
                this.newTask.dueDate = ''
                this.newTask.assignTo = []
                this.newTask.contact = ''
                this.newTask.opportunity = ''
                this.newTask.type = ''
                this.dateHolder = ''
                this.userSource = []
                this.opportunitySource = []
                this.$store.commit('CLOSE_ADD_NEW')
            },
        },
        created() {
            console.log('task opens')
        }

    }

</script>
<style lang="scss">
.priority-button .ant-radio-button-wrapper {
    border-radius:4px;
}
.priority-button .ant-radio-button-wrapper-checked {
    border-color:var(--teal) !important;
}
</style>
<style scoped>
.select-high{
    cursor:pointer;
    background-color:var(--danger);
    color:white;
    border:1px solid var(--danger);
    margin-right:10px;
    line-height:1.6;
    padding-left:15px;
    padding-right:15px;
    border-radius:4px;
}
.select-med{
    cursor:pointer;
    background-color:var(--orange);
    color:white;
    border:1px solid var(--orange);
    margin-right:10px;
    line-height:1.6;
    padding-left:15px;
    padding-right:15px;
    border-radius:4px;
}
.select-low{
    cursor:pointer;
    background-color:var(--teal);
    color:white;
    border:1px solid var(--teal);
    line-height:1.6;
    padding-left:15px;
    padding-right:15px;
    border-radius:4px;
}
.high-priority {
    cursor:pointer;
    color:var(--danger);
    border:1px solid #9EA0A5;
    line-height:1.6;
    border-radius:4px;
    padding-left:15px;
    padding-right:15px;
    margin-right:10px;
}
.high-priority:hover{
    cursor:pointer;
    background-color:var(--danger);
    border:1px solid var(--danger);
    color:white;
    margin-right:10px;
}
.med-priority {
    cursor:pointer;
    color:var(--orange);
    border:1px solid #9EA0A5;
    line-height:1.6;
    border-radius:4px;
    padding-left:15px;
    padding-right:15px;
    margin-right:10px;
}
.med-priority:hover{
    cursor:pointer;
    background-color:var(--orange);
    border:1px solid var(--orange);
    color:white;
    margin-right:10px;
}
.low-priority {
    cursor:pointer;
    color:var(--teal);
    border:1px solid #9EA0A5;
    line-height:1.6;
    border-radius:4px;
    padding-left:15px;
    padding-right:15px;
}
.low-priority:hover{
    cursor:pointer;
    background-color:var(--teal);
    border:1px solid var(--teal);
    color:white;
}
</style>
