<template>
        <a-upload
            v-model="img"
            
            name="avatar"
            listType="picture-card"
            class="bh-file-selector"
            :showUploadList="false"
            :beforeUpload="beforeUpload"
            @change="handleChange"
            >
            
            <a-icon type="loading" v-if="loading"/>
            <div v-else-if="!fileType" class="image-holder-box dF jC aC" >
                <i v-if="plus && !loading && !fileType" class="fe fe-image" />
            </div>
            
            <div v-else-if="fileType.includes('image')" class="image-holder-box relative" :style="{backgroundImage:`url(${img})`}" >
                <div v-if="removable" class="bg-white absolute img-holder-del" @click.stop="delImage"><a-icon type="close" /></div>
            </div>
            <div v-else class="image-holder-box dF jC aC">
                <div :data-type="`.${img.split('.')[img.split('.').length - 1]}`" class="file-icon file-icon-xl"></div>
            </div>
         
            <img :src="img" v-if="type === 'images'" width="0" height="0"  class="image-holder-sized" style="display:none"/>


           
        </a-upload>

</template>

<script>
import {getBase64} from 'bh-mod'
import mime  from 'mime-types'

export default {
    props:{
        plus:{
            type:Boolean,
            default:false
        },
        value:{
            type:String,
            required:true
        },
        type:{
            type:String,
            default:'images'
        },
        removable:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            img:this.value,
            loading:false,
            fileType:mime.lookup(this.value)
        }
    },
    watch:{
        img(val){
            
            let self = this
            let ext = val.split('.')[val.split('.').length -1] 
            ext = ext.toLowerCase() 

            if (!val.includes('https')) val = `https://${val}`

            if (ext === 'png' || ext === 'gif' || ext === 'jpg' || ext === 'webp'){

                let img = document.createElement('img');
                img.onload = () => {
                    console.log('width => ',img.naturalWidth);
                    console.log('height => ',img.naturalHeight);
                    let width = img.naturalWidth
                    let height = img.naturalHeight
                    if (ext !== 'svg' && (!width || !height) ) {
                        self.img = ''
                        return self.$message.error('Image Error. Not a Valid Image')
                    }
                    self.$emit('input',val)
                    self.$emit('sized',{ext,width,height})
                }
                img.src = val;
            } else {
                self.$emit('input',val)
            }
            


        }
    },
    methods:{
        delImage(){
            this.img = ''
            this.fileType = ''
            this.$emit('input','')
        },
         async handleChange(info) {
    
            console.log('INFO FILE', info.file)
            
            this.loading = true
            let formData = new FormData()
            formData.append('files', info.file, info.file.name);
            formData.append('path', this.$bhapp);
            formData.append('instance', this.$store.state.instance.id);

            let avatarUrl = false
            let avatarId = false

                // let okay = false
                // for (var [key, value] of formData.entries()) { 
                //     if (value) okay = true
                //     console.log('THIS FORM DATRA',key, value);
                // }

            let url = `/upload`;
            this.$api.post(url,formData,{headers: { 'Content-Type': 'multipart/form-data' }}).then( ({data}) => {
                this.loading = false
                data = data[0]
                if (data.url){
                    this.img = data.url
                } else {
                    this.$message.error('Oops. Something went wrong.')    
                }
            }).catch(err => {
                this.loading = false
                this.$message.error(this.$err(err))
            })

            // // Get this url from response in real world.
            // getBase64(info.file, imageUrl => {
            //     this.img = imageUrl;
            //     this.loading = false;
            // });
            // this.$emit('fromDrag',this.formData)

        },
        beforeUpload(file) {
            this.loading = true
            const isJPG = file.type === 'image/jpeg';
            const isLtM = file.size / 1024 / 1024 < 5;
            if (!isLtM) {
                this.$message.error('Image must smaller than 5MB!');
                return false
            }
            return false;
        },
        selectedImg(item){
            if (typeof item === 'string') item = {url:item}
            this.img = item.url
            this.fileType = mime.lookup(item.url)
            // this.category.icon = item.url
            
        },
        selectMedia(){
			this.$store.commit('SET_MULTIPLE_IMAGES', false)
            this.$store.commit('MEDIA_SELECT',{callback:this.selectedImg,type:this.type})
        },
        fromLibrary(){
            this.$store.commit('LIBRARY_SELECT',{callback:this.selectedImg,type:'icons'})
        },
    },
    created(){
        console.log('VALUUEEEEE', this.value)
        this.img = this.value
    },
    mounted(){
        setTimeout(() => {
            let self = this
            
            let dom = this.$el.querySelector('.bh-file-selector input[type=file]')
            delete dom.onclick
            dom.onclick = e => {
                e.preventDefault()
                self.selectMedia()
            }
        }, 1);
        // let input = dom.querySelector('input[type=file]')
        // input.addEventListener('click', e => e.preventDefault())
    }
}
</script>

<style scoped lang="scss">
    // .bh-file-selector{
        // .ant-upload{
        //     position:relative !important;
        //     display:none;
        // }
        // 'Feather' !important
    // }
    .img-holder-del{

        opacity:0;
        pointer-events: none;
        width:25px; 
        height:25px; 
        border-radius:100%; 
        display:flex; 
        justify-content:center; 
        align-items:center; 
        top:50%; left:50%; 
        transform:translate3d(-50%,-40%,0);
        transition:transform .2s ease-out, opacity .2s ease-out;

    }

    .image-holder-box{

        background-repeat:no-repeat;
        background-size:contain;
        background-position: center;
        width:100px;
        height:100px;
        
        &:hover{
            .img-holder-del{
                opacity:1;
                pointer-events: all;
                transform:translate3d(-50%,-50%,0);
            }
        }

    }
</style>