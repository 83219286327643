<template>
    <div>
        <a-modal
            v-if="settings.options"
            :visible="(opportunityModal.type === 'Opportunity' || opportunityModal.type == 'Convert Opportunity' || opportunityModal.type == 'Lead Opportunity') && opportunityModal.visible"
            @cancel="onClose"
            @ok="onClose"
            :footer="null"
            :bodyStyle="{padding: '30px'}"
            width="800px"
            centered
            >
            <BHLoading :show="loading"/>
            <h5 class="mb-5">{{opportunityModal.type === 'Opportunity' || opportunityModal.type == 'Lead Opportunity' ? 'Add New Opportunity':`Convert ${defaultName} to an Opportunity`}}</h5>

            <a-form-model ref="ruleForm" :model="opportunity" :rules="rules">
                <a-row :gutter="16">
                    <a-col :span="12">
                        <!-- <a-form-model-item label="Deal Name" prop="name">
                            <a-input v-model="opportunity.name" size="large" placeholder="Deal name" />
                        </a-form-model-item> -->
                        <a-form-model-item :label="instance.productType == 'lowrise' ? 'Lot' : 'Unit'" prop="product">
                            <!-- <a-select v-if="instance.productType == 'lowrise'" v-model="opportunity.product" size="large" @change="selectLot">
                                <template v-for="(prod,prodI) in products">
                                    <a-select-option :disabled="prod.status == 'available' ? false : true" :value="prod.id" :key="prod+prodI">{{prod.type[0].toUpperCase() + prod.type.slice(1) + ' ' + prod.name}}</a-select-option>
                                </template>
                            </a-select> -->
                            <a-cascader v-if="instance.productType == 'lowrise' && opportunityModal.visible" :options="products" size="large" @change="selectLot" placeholder="Select a lot" />
                            <a-cascader v-if="instance.productType == 'highrise' && opportunityModal.visible" :options="products" size="large" @change="selectProduct" placeholder="Select a unit" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12" v-if="leadOpportunity == ''">
                        <div class="dF" style="line-height: 26.5px; padding-top: 5px; font-size: 14px; color: #40454c;">
                            <div class="dF mr-3"><div>Primary Contact</div>&nbsp;<div style="color:var(--danger)">*</div></div>
                        </div>
                        <a-form-model-item prop="contact">
                            <searchContact v-if="opportunityModal.type === 'Opportunity' && opportunityModal.visible" @updatecontact="updatePerson" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12" v-else>
                        <div class="dF aC" style="line-height: 26.5px; padding-top: 5px; font-size: 14px; color: #40454c;">
                            <div class="dF mr-3"><div>Primary Contact</div>&nbsp;<div style="color:var(--danger)">*</div></div>
                            <i class="fe fe-lock" />
                        </div>
                        <a-input size="large" :default-value="defaultName" disabled />
                    </a-col>
                </a-row>

                <a-row :gutter="16">
                    <a-col :span="12" class="relataive">
                        <div class="dF" style="line-height: 26.5px; padding-top: 5px; font-size: 14px; color: #40454c;">
                            <div class="dF mr-3"><div>Estimated Opportunity Close Date</div>&nbsp;<div style="color:var(--danger)">*</div></div>
                            <a-tooltip overlayClassName="change-tooltip-color">
                                <template slot="title">
                                    Select the estimated close date of the opportunity, not when the home will close
                                </template>
                                <a-icon type="question-circle" style="line-height: 22.5px; margin-top: 5px; color: var(--orange);" />
                            </a-tooltip>
                        </div>
                        <a-form-model-item prop="closeDate">
                            <a-date-picker v-model="opportunity.closeDate" size="large" placeholder="dd/mm/yyyy" style="width: 100%;" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <div class="dF" style="line-height: 26.5px; padding-top: 5px; font-size: 14px; color: #40454c;">
                            <div class="dF mr-3"><div>Deal Stage</div>&nbsp;<div style="color:var(--danger)">*</div></div>
                            <a-tooltip overlayClassName="change-tooltip-color">
                                <template slot="title">
                                    Select the Deal Stage
                                </template>
                                <a-icon type="question-circle" style="line-height: 22.5px; margin-top: 5px; color: var(--orange);" />
                            </a-tooltip>
                        </div>
                        <a-form-model-item prop="stage">
                            <a-select @change="changeStage" v-model="opportunity.stage" style="width: 100%;" size="large">
                                <div slot="dropdownRender" slot-scope="menu">
                                    <v-nodes :vnodes="menu" />
                                    <a-divider style="margin: 4px 0;" />
                                    <div
                                        style="padding: 8px; cursor: pointer; color:var(--orange)"
                                        @mousedown="e => e.preventDefault()"
                                        @click="addNewStage"
                                    >
                                        <i style="font-size:15px;" class="fe fe-edit-2 mr-2" /> Add New Deal Stage
                                    </div>
                                </div>
                                <template v-if="settings.options && settings.options.stages && settings.options.stages.list">
                                    <template v-for="(stage,stageI) in settings.options.stages.list">
                                        <a-select-option v-if="stage && stage.id != 'transaction'" :key="stage+stageI" :value="stage.id">
                                            {{stage.name}}
                                        </a-select-option>
                                    </template>
                                </template>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>

                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-model-item label="Value" prop="dealValue">
							<a-input-number style="width:100%" v-model="opportunity.dealValue" :formatter="price => `$ ${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :min="0" placeholder="Add deal value" ></a-input-number>
						</a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="Deal Source" prop="dealSource">
                            <a-select v-model="opportunity.dealSource" style="width: 100%;" size="large">
                                <div slot="dropdownRender" slot-scope="menu">
                                    <v-nodes :vnodes="menu" />
                                    <a-divider style="margin: 4px 0;" />
                                    <div
                                        style="padding: 8px; cursor: pointer; color:var(--orange)"
                                        @mousedown="e => e.preventDefault()"
                                        @click="addNewSource"
                                    >
                                        <i style="font-size:15px;" class="fe fe-edit-2 mr-2" /> Add New Deal Source
                                    </div>
                                </div>
                                <a-select-option v-for="(source,sourceI) in dealSources" :key="source+sourceI" :value="source.id ? source.id : source.value">
                                    {{source.name}}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>

                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-model-item label="Visibility" prop="visibility">
                            <a-select @change="changeVisibility" v-model="opportunity.visibility" style="width: 100%;" size="large">
                                <a-select-option value="everyone">
                                    Everyone
                                </a-select-option>
                                <a-select-option value="owners">
                                    Only Sales Owner(s)
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="Sales Owner(s)" prop="owners">
                            <a-select
                                v-model="opportunity.owners"
                                mode="multiple"
                                placeholder="Add owner"
                                size="large"
                                :not-found-content="null"
                                :filter-option="filterOption"
                                @search="searchAssign">
                                <a-select-option v-for="user in userSource" :key="user.id" :value="user.id">
                                    {{ user.name }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>

                <a-row>
                    <a-col :span="24">
                        <div class="dF" style="line-height: 26.5px; padding-top: 5px; font-size: 14px; color: #40454c;">
                            <div class="mr-3"><div>Interested In</div></div>
                            <a-tooltip overlayClassName="change-tooltip-color">
                                <template slot="title">
                                    Add description of opportunity
                                </template>
                                <a-icon type="question-circle" style="line-height: 22.5px; margin-top: 5px; color: var(--orange);" />
                            </a-tooltip>
                        </div>
                        <a-form-model-item prop="interested">
                            <a-textarea
                                v-model="opportunity.interested"
                                :auto-size="{ minRows: 4, maxRows: 6 }"
                                placeholder="Add description of opportunity"
                                />
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>

            <div style="text-align: right;">
                <a-button @click="onClose" class="mt-3 mr-3 cancel-button" size="large" style="width: 125px;">CANCEL</a-button>
                <a-button @click="onSubmit" style="width: 125px;" size="large" type="primary">CREATE</a-button>
            </div>
        </a-modal>
        <a-modal @cancel="closeNoti" :visible="notiVisible" :centered="true" :footer="null">
            <h5>{{`${defaultName} has been successfully converted!`}}</h5>
            <div>{{`${defaultName} - has been added to your opportunity pipeline.`}}</div>
            <div class="mt-3 dF jE">
                <a-button @click="returnLeads" class="mr-3" style="color:var(--orange); border-color:var(--orange)">RETURN TO LEADS</a-button>
                <a-button @click="viewOpportunities" type="primary">VIEW OPPORTUNITIES</a-button>
            </div>
        </a-modal>
    </div>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'
import searchContact from '@/components/common/searchContact'
import moment from 'moment'

export default {
    components:{
        BHLoading,searchContact,
        VNodes: {
        functional: true,
        render: (h, ctx) => ctx.props.vnodes,
        },
    },
    data() {
        return {
            closeDeal:false,
            opportunity:{
                name:'',
                contact:'',
                closeDate:'',
                company:'',
                owners:[],
                stage:'',
                dealValue:'',
                dealSource:'nosource',
                probability:20,
                visibility:'everyone',
                interested:'',
                floor:'',
                product:''
            },
            defaultName:'',
            userSource:[],
            loading:false,
            notiVisible:false,

            rules: {
                product: [
                    { required: true, message: 'Please select a product', trigger: 'blur' },
                ],
                contact: [
                    { required: true, message: 'Please input a contact', trigger: 'blur' },
                ],
                closeDate: [
                    { required: true, message: 'Please input a close date' },
                ],
                stage: [
                    { required: true, message: 'Please select a stage', trigger: 'blur' },
                ],
                dealValue: [
                    { required: true, message: 'Please input a deal value', trigger: 'blur' },
                ],
                owners:[
                    { required: true, message: 'Please select one', trigger: 'blur' },
                ]
            },
        }
    },
    watch:{
        visible:{
            handler(val) {
                if (val && this.opportunityModal.type && (this.opportunityModal.type == 'Opportunity' || this.opportunityModal.type == 'Convert Opportunity' || this.opportunityModal.type == 'Lead Opportunity')) {
                    let obj = {}
                    obj = this.owner
                    obj.name = `${this.owner.firstName} ${this.owner.lastName}`
                    if (this.leadOpportunity != '') {
                        if (this.$store.state.contacts.allContacts[this.leadOpportunity] && this.$store.state.contacts.allContacts && this.$store.state.contacts.allContacts[this.leadOpportunity].owners) {
                            this.$store.state.contacts.allContacts[this.leadOpportunity].owners.forEach(user => {
                                let id = user
                                if (id.id) id = id.id
                                let find = this.users.find(x => x.id == id)
                                if (find) {
                                    let obj = {}
                                    obj = find
                                    obj.name = `${find.firstName} ${find.lastName}`
                                    this.userSource.push(obj)
                                    this.opportunity.owners.push(find.id)
                                }
                            })
                        } else if (this.$store.state.contacts.externalContacts[this.leadOpportunity] && this.$store.state.contacts.externalContacts && this.$store.state.contacts.externalContacts[this.leadOpportunity].owners) {
                            this.$store.state.contacts.externalContacts[this.leadOpportunity].owners.forEach(user => {
                                let id = user
                                if (id.id) id = id.id
                                let find = this.users.find(x => x.id == id)
                                if (find) {
                                    let obj = {}
                                    obj = find
                                    obj.name = `${find.firstName} ${find.lastName}`
                                    this.userSource.push(obj)
                                    this.opportunity.owners.push(find.id)
                                }
                            })
                        }
                    }
                    if (!this.userSource.map(x => x = x.id).includes(this.owner.id)) {
                        this.userSource.push(obj)
                        this.opportunity.owners.push(this.owner.id)
                    }
                    this.opportunity.stage = ''
                    this.opportunity.dealSource = 'nosource'
                    if (this.opportunityModal.object && Object.keys(this.opportunityModal.object).length) {
                        console.log('object', this.opportunityModal.object)
                    }
                } else if (!val) {
                    this.opportunity.owners = []
                    this.userSource = []
                    this.$store.commit('CLEAR_LEAD_ACTION')
                }
            }
        },
        leadOpportunity:{
            handler(val) {
                if (val != '' && (this.opportunityModal.type == 'Opportunity' || this.opportunityModal.type == 'Convert Opportunity' || this.opportunityModal.type == 'Lead Opportunity')) {
                    this.opportunity.contact = val
                    if (this.$store.state.contacts.allContacts[val] && this.$store.state.contacts.allContacts) {
                        let find = this.$store.state.contacts.allContacts[val]
                        if(find && find.firstName && find.lastName && find.firstName.trim() && find.lastName.trim()) this.defaultName = `${find.firstName} ${find.lastName}`
                        else if (find) this.defaultName = `${find.email}`
                    } else if (this.$store.state.contacts.externalContacts && this.$store.state.contacts.externalContacts[val]) {
                        let find = this.$store.state.contacts.externalContacts[val]
                        if(find && find.firstName && find.lastName && find.firstName.trim() && find.lastName.trim()) this.defaultName = `${find.firstName} ${find.lastName}`
                        else if (find) this.defaultName = `${find.email}`
                    }
                    console.log('user', this.users, this.defaultName)
                }
            }
        }
    },
    computed:{
        products(){
            let products = JSON.parse(JSON.stringify(this.origProducts))
            console.log('PRODUCTS', products)
            if (this.instance.productType == 'lowrise'){
                let elevations = JSON.parse(JSON.stringify(this.elevations))
                console.log('ORIG ELEVATIONS', this.origElevations)
                products.sort((a,b) => {
                    return a.name - b.name
                })

                let newProducts = []
                products.forEach(x => {
                    let obj = {
                        value:'',
                        label:'',
                        children:[]
                    }
                    obj.value = x.id
                    obj.label = 'Lot ' + x.name

                    x.units.forEach(y => {
                        let foundUnit = elevations.find(z => {
                            if (z && z.hasOwnProperty('id')) return z.id == y
                            return false
                        })
                        if (foundUnit){
                            let objChild = {
                                value:'',
                                label:'',
                                disabled:false
                            }
                            objChild.value = foundUnit.id
                            objChild.label = foundUnit.name
                            obj.children.push(objChild)
                        }
                    })
                    newProducts.push(obj)
                })
                return newProducts
            } else if (this.instance.productType == 'highrise'){
                let newProducts = []
                products.forEach(x => {
                    let obj = {
                        value:'',
                        label:'',
                        children:[]
                    }
                    obj.value = x.id
                    obj.label = x.name

                    x.units.forEach(y => {
                        let objChild = {
                            value:'',
                            label:'',
                            disabled:false
                        }
                        if (y.salesStatus != 'available') objChild.disabled = true
                        objChild.value = y.id
                        if (y.name == y.unitNumber) objChild.label = 'Unit ' + y.unitNumber
                        else objChild.label = y.name + ` (Unit ${y.unitNumber})`
                        obj.children.push(objChild)
                    })
                    newProducts.push(obj)
                })
                return newProducts
            }
        },
        elevations(){
            if (this.instance.productType == 'lowrise'){
                let elevations = JSON.parse(JSON.stringify(this.origElevations))
                elevations = elevations.map(x => {
                    if (x.unitGroup && x.unitGroup.hasOwnProperty('name')){
                        return {
                            id:x.id,
                            name:`${x.unitGroup.name} ${x.name}`
                        }
                    }
                })
                return elevations
            }
        },
        origElevations(){
            return this.$store.state.elevations
        },
        origProducts(){
            return this.$store.state.products
        },
        allContacts() {
            return this.$store.state.contacts.allContacts || {}
        },
        externalContacts() {
            return this.$store.state.contacts.externalContacts || {}
        },
        leadOpportunity() {
            return this.$store.state.contacts.leadAction
        },
        dealSources() {
            if (this.settings && this.settings.options && this.settings.options.dealSources && this.settings.options.dealSources.length > 0) {
                return this.settings.options.dealSources
            } else {
                return [
                    {name:'No Source', id:'nosource'},
                    {name:'Email', id:'email'},
                    {name:'Cold Call', id:'coldcall'},
                    {name:'Advertising', id:'advertising'}
                ]
            }
        },
        visible() {
            return this.$store.state.addNew.visible
        },
        owner() {
            return this.$store.state.user.user
        },
        opportunityModal(){
            return this.$store.state.addNew
        },
        settings(){
            return this.$store.state.contacts.allSettings.app || {}
        },
        currentPage:{
            get(){
                return this.$store.state.appData.currentPage
            },
            set(val){
                this.$store.commit('SET_CONTACTS_PAGE', val)
            }
        },
        instance(){
            return this.$store.state.instance
        },
        users(){
            return this.$store.state.contacts.users
        },
        loadingPage:{
            get(){
                return this.$store.state.appData.loadingPage
            },
            set(val){
                this.$store.commit('LOAD_PAGE',val)
            }
        }
    },
    methods:{
        selectLot(e){
            this.opportunity.floor = e[0]
            this.opportunity.product = e[1]
            console.log('OPPORTUNITY', this.opportunity.floor, this.opportunity.product)
            let price = 0
            this.origProducts.forEach(x => {
                if (x.id == this.opportunity.floor) {
                    let premiums = Object.values(x.other.premiums)
                    premiums.forEach(y => {
                        if (y.hasOwnProperty('active') && y.active) {
                            price += y.price
                        }
                    })
                }
            })
            let foundElevation = this.origElevations.find(x => {
                if (x && x.hasOwnProperty('id')) return x.id == this.opportunity.product
                return false
            })
            if (foundElevation){
                if (foundElevation.packages && foundElevation.packages.length){
                    price += foundElevation.packages[0].price
                }
            }
            this.opportunity.dealValue = price
        },
        selectProduct(e){
            this.opportunity.floor = e[0]
            this.opportunity.product = e[1]
            console.log('OPPORTUNITY', this.opportunity)
            this.origProducts.forEach(x => {
                if (x.id == this.opportunity.floor) {
                    x.units.forEach(y => {
                        if (y.id == this.opportunity.product) {
                            this.opportunity.dealValue = y.packages[0].price
                        }
                    })
                }
            })
        },
        changeStage(e) {
            if (this.settings.options && this.settings.options.stages && this.settings.options.stages.list) {
                let find = this.settings.options.stages.list.find(x => x && x.id == e)
                if (find && find.probability) this.opportunity.probability = find.probability
                else if (find) this.opportunity.probability = 20
            }
        },
        addNewStage() {
            this.$store.commit('OPEN_LEAD_STATUS', {type:'dealStage'})
        },
        addNewSource() {
            this.$store.commit('OPEN_LEAD_STATUS', {type:'dealSource'})
        },
        returnLeads() {
            if (this.$route.path && !this.$route.path.includes('/leads')) {
                this.$router.push('/leads')

            }
            this.closeNoti()
        },
        viewOpportunities() {
            this.$router.push('/opportunities/sales')
            this.closeNoti()
        },
        changeVisibility(e) {
            if (e == 'owners') {
                // this.opportunity.owners = this.users.map(x => x = x.id)
                let ids = this.userSource.map(x => x = x.id)
                this.users.forEach(user => {
                    user.name = `${user.firstName} ${user.lastName}`
                    if (!ids.includes(user.id)) this.userSource.push(user)
                })
            } else if (e == 'everyone') {
                // this.opportunity.owners = [this.owner.id]
                let obj = {}
                obj = this.owner
                obj.name = `${this.owner.firstName} ${this.owner.lastName}`
                this.userSource = [obj]
            }
        },
        moment,
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        searchAssign(input){
            if(input && input.length>1) {
                this.users.forEach(x => {
                    x.name = x.firstName+ ' ' + x.lastName
                })
                this.userSource = this.users.filter(({name}) => {
                    name = name.toLowerCase()
                    input = input.toLowerCase()
                    return name.includes(input)
                })
            } else {
                return this.userSource = []
            }
            console.log('searching' , this.userSource)
        },
        updatePerson(contact){
            console.log('UPDATE PERSON RAN', contact)
            this.opportunity.contact = contact
        },
        closeNoti() {
            this.notiVisible = false
        },
        onClose(){
            let type = this.opportunityModal.type
            console.log('ONCLOSE RAN')
			if(this.$refs['ruleForm']){
				this.$refs['ruleForm'].resetFields();
			}
            this.$store.commit('CLOSE_ADD_NEW')
            this.opportunity = {
                name:'',
                contact:'',
                closeDate:'',
                company:'',
                owners:[],
                stage:'',
                dealValue:'',
                dealSource:'nosource',
                probability:20,
                visibility:'everyone',
                interested:'',
                floor:'',
                product:''
            },
            this.userSource = []
            if ((type == 'Convert Opportunity' || type == 'Lead Opportunity') && this.closeDeal) {
                this.notiVisible = true
            }
            this.closeDeal = false
        },
        onSubmit(){

            this.$refs.ruleForm.validate(async valid => {

                if (valid) {
                    console.log('CREATE RAN')
                    this.loading = true

                    let sendObj = JSON.parse(JSON.stringify(this.opportunity))
                    if (this.instance.productType == 'lowrise'){
                        let findFloor = this.origProducts.find(x => x.id == sendObj.floor)
                        if (findFloor){
                            let findProduct = this.origElevations.find(x => x.id == sendObj.product)
                            if (findProduct){
                                sendObj.name = `Lot ${findFloor.name} ${findProduct.unitGroup && findProduct.hasOwnProperty('name') ? '/ ' + findProduct.unitGroup.name : ''} ${findProduct.name}`
                            } else {
                                sendObj.name = '(No Product)'
                            }
                        } else {
                            sendObj.name = '(No Product)'
                        }
                    } else if (this.instance.productType == 'highrise'){
                        let findFloor = this.origProducts.find(x => x.id == sendObj.floor)
                        if (findFloor){
                            let findProduct = findFloor.units.find(x => x.id == sendObj.product)
                            if (findProduct){
                                if (findProduct.name == findProduct.unitNumber) sendObj.name = 'Unit ' + findProduct.unitNumber
                                else sendObj.name = findProduct.name + ` (Unit ${findProduct.unitNumber})`
                            } else {
                                sendObj.name = '(No Product)'
                            }
                        } else {
                            sendObj.name = '(No Product)'
                        }
                    }
                    sendObj.closeDate = parseInt(this.moment(sendObj.closeDate).format('X'))*1000

                    console.log('sendOjb', sendObj)
                    this.$api.post(`/opportunities/:instance`,sendObj).then( ({data}) => {
                        console.log('DATAA COMING BACK', data)
                        this.$store.commit('ADD_OPPORTUNITY', data)
                        this.loading = false
                        this.closeDeal = true
                        this.onClose()
                    }).catch(err => {
						this.loading = false
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
    },
    created(){

    },
    mounted(){

    }
}
</script>

<style lang="scss">
    .popoverStyle .ant-popover-inner-content {
        padding: 0;
        background-color: white;
        border-radius: 20px;
    }
    .change-tooltip-color .ant-tooltip-content .ant-tooltip-inner{
        background-color:#3f3356;
    }
</style>

<style lang="scss" scoped>
    .popoverContent{
        height: 35px;
        width: 150px;
        line-height: 35px;
        padding-left: 10px;
    }
    .popoverContent:hover{
        background-color: var(--off-white-light);
        cursor: pointer;
    }
</style>
