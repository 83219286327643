<template>
  <div id="app">
    <BHLoading :show="$store.state.loading" />
    <a-modal
      :title="modal.title"
      :visible="modal.show"
      @ok="$store.dispatch('CONFIRM_MODAL')"
      :confirmLoading="modal.load"
      @cancel="$store.commit('CLOSE_MODAL')"
      >
      <p>{{modal.text}}</p>
    </a-modal>
    <MediaSelector :multiple="multipleImages ? true : false"/>
    <AddNewModal />
    <PreviewModal />
    <EditModal />
    <contactModal />
    <opportunityModal />
    <taskModal />
    <AppointmentsModal />
	<EventsModal />
    <noteModal />
    <EmailModal />
    <localization></localization>
    <SaveEmailTemplate />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Localization from 'bh-mod/localization'
import MediaSelector from 'bh-mod/components/file/MediaSelector'
import contactModal from '@/components/common/contactModal'
import taskModal from '@/components/common/taskModal'
import PreviewModal from '@/components/common/PreviewModal'
import opportunityModal from '@/components/common/opportunityModal'
import AppointmentsModal from '@/components/contacts/AppointmentsModal'
import EventsModal from '@/components/contacts/EventsModal'
import noteModal from '@/components/common/noteModal'
import EmailModal from '@/components/common/EmailModal'
import SaveEmailTemplate from '@/components/common/SaveEmailTemplate'
import BHLoading from 'bh-mod/components/common/Loading'
import AddNewModal from '@/components/common/AddNewModal'
import EditModal from '@/components/contacts/EditModal'

export default {
  name: 'app',
  components: {BHLoading, Localization,MediaSelector,contactModal,opportunityModal, taskModal, AppointmentsModal, EventsModal, noteModal, EmailModal, SaveEmailTemplate, AddNewModal, EditModal, PreviewModal},
  computed: {
    ...mapGetters(['user']),
    modal(){
      return this.$store.state.contacts.modal
    },
    instance(){
      return this.$store.state.instance
    },
	multipleImages(){
		return this.$store.state.contacts.multipleImages
	},
	appDataLoaded(){
		return this.$store.state.contacts.appDataLoaded
	}
  },
  watch: {
    '$route'(to, from) {
      const query = Object.assign({}, to.query)
      this.$store.commit('SETUP_URL_SETTINGS', query)
    },
	appDataLoaded(val){
		if (val){
			this.$store.dispatch('DO_TAGS');
			this.$store.dispatch('DO_SOURCE');
		}
	}
  },
  methods:{

  }
}
</script>
<style>
  #app{
    min-height: 100vh;
  }
  .font-bold{
    font-weight:600;
  }

 /* table left-right scroll arrows css */
.scroll-button {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	background-color: rgb(220 220 220 / 50%);
	border: none;
	color: #000;
	font-size: large;
	padding: 10px;
	cursor: pointer;
	height: 100%;
	display: flex;
	align-items: center;
}

.scroll-left {
	left: 0;
}

.scroll-right {
	right: 0;
}

.hide {
	display: none;
}
</style>
