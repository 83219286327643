<template>
    <div style="height: 100%">
        <LeftNavBar
            :title="selectedAppInitial"
            :items="navItems"
            @opened="navOpened"
            :selectedValue="$store.state.sidebarSelection"
        />
    </div>
</template>

<script>
import LeftNavBar from "bh-mod/components/common/LeftNavBar";

export default {
    components: { LeftNavBar },
    name: "menu-left",
    data() {
        return {};
    },
    computed: {
        selectedAppInitial() {
            return (
                (this.$store.state.theApp &&
                    this.$store.state.theApp.media &&
                    this.$store.state.theApp.media.initial) ||
                "CRM"
            );
        },
        navItems() {
            let items = [
                {
                    label: "My Work Day",
                    value: "My Work Day",
                    iconUrl: require("@/assets/sideIcons/overview.svg"),
                },
                {
                    label: "Leads",
                    value: "Leads",
                    iconUrl: require("@/assets/sideIcons/contacts.svg"),
                },
                {
                    label: "Opportunities",
                    value: "Opportunities",
                    iconUrl: require("@/assets/sideIcons/opportunities.svg"),
                },
                {
                    label: "Email",
                    value: "Email",
                    iconUrl: require("@/assets/sideIcons/email.svg"),
                    options: [],
                    children: [],
                },
                {
                    label: "Calendar",
                    value: "Calendar",
                    iconUrl: require("@/assets/sideIcons/calendar.svg"),
                },
                {
                    label: "Tasks",
                    value: "Tasks",
                    iconUrl: require("@/assets/sideIcons/tasks.svg"),
                    options: [],
                    children: [],
                },
                {
                    label: "Appointments",
                    value: "Appointments",
                    iconUrl: require("@/assets/sideIcons/appointment.svg"),
                    options: [],
                    children: [],
                },
				{
                    label: "Events",
                    value: "Events",
                    iconUrl: require("@/assets/sideIcons/events.svg"),
                    options: [],
                    children: [],
                },

                {
                    divider: true,
                },
                {
                    label: "Settings",
                    value: "Settings",
                    iconUrl: require("@/assets/sideIcons/settings.svg"),
                },
            ];

            if (
                this.sidebarSelection == "Tasks" ||
                this.sidebarSelection == "Active" ||
                this.sidebarSelection == "Completed"
            ) {
                items.splice(6, 0, {
                    label: "Active",
                    value: "Active",
                    noIcon: "none",
                });
                items.splice(7, 0, {
                    label: "Completed",
                    value: "Completed",
                    noIcon: "none",
                });
                return items;
            }

            if (
                this.sidebarSelection == "Opportunities" ||
                this.sidebarSelection == "Sales Pipeline" ||
                this.sidebarSelection == "Archived"
            ) {
                items.splice(3, 0, {
                    label: "Sales Pipeline",
                    value: "Sales Pipeline",
                    iconUrl: require("@/assets/sideIcons/sales.svg")
                });
                items.splice(4, 0, {
                    label: "Archived",
                    value: "Archived",
                    iconUrl: require("@/assets/sideIcons/archived.svg")
                });
                return items;
            }

            if (
                this.sidebarSelection == "Email" ||
                this.sidebarSelection == "Inbox" ||
                this.sidebarSelection == "Sent"
            ) {
                items.splice(4, 0, {
                    label: "Inbox",
                    value: "Inbox",
                    noIcon: "none",
                });
                items.splice(5, 0, {
                    label: "Sent",
                    value: "Sent",
                    noIcon: "none",
                });
                return items;
            }

            return items;
        },
        expandDetails() {
            return this.$store.state.contacts.expandDetails.visible;
        },
        expandOpportunity() {
            return this.$store.state.contacts.expandOpportunity.visible;
        },
        sidebarSelection() {
            return this.$store.state.sidebarSelection;
        },
        lots() {
            return this.$store.state.contacts.lots;
        },
        folders() {
            return this.$store.getters.folders;
        },
        files() {
            return this.$store.getters.allFiles;
        },
    },
    methods: {
        navOpened(e) {
            if (e.value === "My Work Day") {
                if (this.$route.path !== "/") {
                    if (this.expandDetails) {
                        this.$store.commit("CLOSE_EXPAND_DETAILS");
                    }
                    this.$router.push("/");
                }
            }
            if (e.value === "Leads") {
                if (this.$route.path !== "/leads") {
                    if (this.expandDetails) {
                        this.$store.commit("CLOSE_EXPAND_DETAILS");
                    }
                    this.$router.push("/leads");
                }
            } else if (
                e.value === "Opportunities" ||
                e.value === "Sales Pipeline"
            ) {
                if (this.$route.path !== "/opportunities/sales") {
                    if (this.expandOpportunity) {
                        this.$store.commit("CLOSE_EXPAND_OPPORTUNITY");
                    }
                    this.$router.push("/opportunities/sales");
                }
            } else if (e.value === "Archived") {
                if (this.$route.path !== "/opportunities/archived") {
                    this.$router.push("/opportunities/archived");
                }
            } else if (e.value === "Tasks" || e.value === "Active") {
                if (this.$route.path !== "/tasks/active") {
                    if (this.expandDetails) {
                        this.$store.commit("CLOSE_EXPAND_DETAILS");
                    }
                    if (this.expandOpportunity) {
                        this.$store.commit("CLOSE_EXPAND_OPPORTUNITY");
                    }
                    this.$router.push("/tasks/active");
                }
            } else if (e.value === "Completed") {
                if (this.$route.path !== "/tasks/completed") {
                    if (this.expandDetails) {
                        this.$store.commit("CLOSE_EXPAND_DETAILS");
                    }
                    if (this.expandOpportunity) {
                        this.$store.commit("CLOSE_EXPAND_OPPORTUNITY");
                    }
                    this.$router.push("/tasks/completed");
                }
            } else if (e.value === "Email" || e.value === "Inbox") {
                if (this.$route.path !== "/email/inbox") {
                    this.$router.push("/email/inbox");
                }
            } else if (e.value === "Sent") {
                if (this.$route.path !== "/email/sent") {
                    this.$router.push("/email/sent");
                }
            } else if (e.value === "Calendar") {
                if (this.$route.path !== "/calendar") {
                    this.$router.push("/calendar");
                }
            } else if (e.value === "Appointments") {
                if (this.$route.path !== "/appointments") {
                    this.$router.push("/appointments");
                }
            } else if (e.value === "Events") {
                if (this.$route.path !== "/events") {
                    this.$router.push("/events");
                }
            } else if (e.value === "Settings") {
                if (this.$route.path !== "/settings") {
                    this.$router.push("/settings");
                }
            }
        },

        grandChildClicked(item) {
            console.log("GRANDCHILD CLICKEDDDD", item);
        },
        childClicked(item) {
            console.log("CHILD CLICKEDDD", item);
        },
        editLot(lot) {
            console.log("EDITING LOT", lot);
            this.$store.commit("SHOW_EDIT_LOT", lot);
        },
        handleClick(e) {
            this.$store.commit("changeFolder", e.key);
        },
    },
};
</script>
